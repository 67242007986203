import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { StockProductActionTypes } from '@shared/store/actions/stock-product.actions';

import { ErrorResponse } from '../../models';
import { SupplierPriceContent, SupplierPriceSearchCriteria } from '../../models/supplier-price.model';
import { SupplierPriceActionTypes, SupplierPriceActions } from '../actions/supplier-price.actions';

export interface SupplierPriceState extends EntityState<SupplierPriceContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  supplierPrice: SupplierPriceContent[];
  criteriaObject: SupplierPriceSearchCriteria;
  totalElements: number;
  totalPages: number;
  errorResponse: ErrorResponse;
  showSuccess: boolean;
  isPriceUpdated: boolean;
  allowLoad: boolean;
}

export const adapter: EntityAdapter<SupplierPriceContent> = createEntityAdapter<SupplierPriceContent>();

export const initialSupplierPriceState: SupplierPriceState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  supplierPrice: null,
  criteriaObject: {
    importId: null,
    page: 0,
    size: 20,
    showSuccess: false
  },
  totalElements: 0,
  totalPages: 0,
  errorResponse: null,
  showSuccess: false,
  isPriceUpdated: false,
  allowLoad: false
});

export function supplierPriceReducers(
  state = initialSupplierPriceState,
  action: SupplierPriceActions
): SupplierPriceState {
  switch (action.type) {
    case SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_RESPONSE:
      return adapter.setAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case SupplierPriceActionTypes.SUPPLIER_PRICE_RESET: {
      return {
        ...state,
        ...initialSupplierPriceState
      };
    }
    case SupplierPriceActionTypes.SUPPLIER_PRICE_SHOW_SUCCESS:
      return {
        ...state,
        showSuccess: action.payload
      };
    case SupplierPriceActionTypes.SUPPLIER_PRICE_IS_PRICE_UPDATED:
      return {
        ...state,
        isPriceUpdated: action.payload
      };
    case SupplierPriceActionTypes.SUPPLIER_PRICE_UPDATED_SUPPLIER_NAME:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        { ...state }
      );
    case SupplierPriceActionTypes.SUPPLIER_PRICE_UPDATED_MULTIPLE_SUPPLIER_NAME:
      return adapter.updateMany(
        action.payload.map(supplierPriceContent => {
          return {
            id: supplierPriceContent.id,
            changes: {
              ...supplierPriceContent
            }
          };
        }),
        { ...state }
      );
    case SupplierPriceActionTypes.SUPPLIER_PRICE_INSERT_SUPPLIER:
      return adapter.addOne(action.payload, state);
    case SupplierPriceActionTypes.SUPPLIER_PRICE_ADD_SUPPLIER:
      return adapter.addMany(action.payload, state);
    case SupplierPriceActionTypes.SUPPLIER_PRICE_REMOVE_ONE_SUPPLIER:
      return adapter.removeOne(action.payload, state);
    case SupplierPriceActionTypes.SUPPLIER_PRICE_UPDATE_ONE:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: { ...action.payload }
        },
        {
          ...state
        }
      );

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
