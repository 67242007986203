import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';

import { TDStoreValidatorTypeEnum } from '../enum/merchant-validator-type.enum';
import { productClassesQuery } from '../gql/classes.gql';
import { productGradingQuery } from '../gql/product-grading.gql';
import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  ApproveShelfRequest,
  RequestId,
  ShelfCreateRequest,
  ShelfFixAssetCreateRequest,
  ShelfFixAssetRequestViewResponse,
  ShelfRequestListResponse,
  ShelfRequestListSearchCriteria,
  ShelfRequestViewResponse
} from '../models/shelf-request.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class ShelfRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(
    private readonly http: HttpClient,
    private readonly masterService: MasterService,
    protected readonly logger: NGXLogger
  ) {
    super();
    this.envService = this.env.services.shelfRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getShelfValidate(
    type: TDStoreValidatorTypeEnum,
    value: string,
    urlParams: { [key: string]: string } = null
  ): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.validate, { type, value });
    const params = urlParams ? this.getParams(urlParams) : null;

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'response',
      params
    });
  }

  public searchByCriteria(criteria: ShelfRequestListSearchCriteria): Observable<ShelfRequestListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria);

    return this.http.get<ShelfRequestListResponse>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public saveRequest(shelfRequest: ShelfCreateRequest): Observable<ShelfRequestViewResponse> {
    const url = this.getFullUrl(this.envService.save);

    return this.http.put<ShelfRequestViewResponse>(url, shelfRequest, { headers: this.loaderHeaders() });
  }

  public saveRequestFixAsset(shelfRequest: ShelfFixAssetCreateRequest): Observable<ShelfFixAssetRequestViewResponse> {
    const url = this.getFullUrl(this.envService.save);

    return this.http.put<ShelfFixAssetRequestViewResponse>(url, shelfRequest, { headers: this.loaderHeaders() });
  }

  public submitRequest(shelfRequest: ShelfCreateRequest): Observable<ShelfRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.post<ShelfRequestViewResponse>(url, shelfRequest, { headers: this.loaderHeaders() });
  }

  public getShelfRequestById(requestId: RequestId): Observable<ShelfRequestViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      requestId: requestId.requestId
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public deleteByRequestId(requestId: RequestId): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.delete, {
      requestId: requestId.requestId
    });
    return this.http.delete<any>(url, { headers: this.loaderHeaders(), observe: 'body' });
  }

  public approveRequest(request: ApproveShelfRequest): Observable<ShelfRequestViewResponse> {
    const url = this.getFullUrl(this.envService.approve);

    return this.http.put<ShelfRequestViewResponse>(url, request, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public exportInventoryItem(data: any): Observable<any> {
    const url = this.getFullUrl(this.envService.exportFirstLot);
    const params = this.getParams(data);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.SHELF,
          path: `${this.envService.url}${this.envService.exportFirstLot}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.SHELF,
          route: `${this.envService.url}${this.envService.exportFirstLot}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(0, metricsReq),
      responseType: 'blob' as 'json',
      params
    });
  }

  public getHistoryLogs(requestId: RequestId): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      requestId: requestId.requestId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getProductGrading(): Observable<ApolloQueryResult<any>> {
    return this.masterService.watchQuery({ query: productGradingQuery });
  }

  public getClasses(): Observable<ApolloQueryResult<any>> {
    return this.masterService.watchQuery({ query: productClassesQuery });
  }

  public exportShelf(selected: Array<string>): Observable<any> {
    const url = this.getFullUrl(this.envService.export);

    return this.http.post<any>(
      url,
      { selected },
      {
        headers: this.loaderHeaders(0),
        observe: 'body',
        responseType: 'blob' as 'json'
      }
    );
  }
}
