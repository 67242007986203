<ng-container *ngIf="(resultList$ | async).totalElements === 0 && hasSearchCriteria">
  <div class="no-result-container text-center">
    <div class="d-flex justify-content-center">
      <img src="/assets/images/search-not-found.svg" alt="No Search Result" />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(resultList$ | async).totalElements === 0 && !hasSearchCriteria">
  <div class="no-result-container text-center">
    <div class="d-flex justify-content-center">
      <img alt="Data not found" src="/assets/images/no-data.svg" />
    </div>
  </div>
</ng-container>
