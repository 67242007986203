<!--<div class="d-flex mb-3" *appHasPermission="['store_eq']">-->
<!--  <button type="button" class="btn btn-standard" *ngIf="!waitingDevice && !activeDevice" (click)="onRegisterPOS()">-->
<!--    <em class="icon-gen-user-pass"></em>-->
<!--    Generate POS Registration Code-->
<!--  </button>-->

<!--  <button type="button" class="btn btn-standard" *ngIf="waitingDevice" (click)="onDeletePOS()">-->
<!--    <em class="icon-delete" title="Delete"></em>-->
<!--    Delete Device-->
<!--  </button>-->

<!--  <button type="button" class="btn btn-standard" *ngIf="activeDevice" (click)="onDeactivatePOS()">-->
<!--    <em class="icon-deactivate" title="Deactivate"></em>-->
<!--    Deactivate Device-->
<!--  </button>-->
<!--</div>-->

<div class="row no-gutters">
  <div class="col-4 px-2" *ngFor="let device of devices; let i = index">
    <div class="container-fluid mb-3 store-device-card">
      <div class="row header g-0">
        <label class="col-2 d-flex align-items-center font-weight-bold device-no">
          {{ getDeviceNo | pureFn: i + 1 }}
        </label>
        <div class="col-9 d-flex justify-content-end">
          <ng-container *appHasPermission="['store_eq']">
            <button
              type="button"
              class="btn btn-standard"
              (click)="onRegister(i + 1)"
              *ngIf="!device.deviceId || device.status === posStatus.DEACTIVATED"
            >
              <em class="icon-gen-user-pass"></em>
              Generate Registration Code
            </button>
          </ng-container>
        </div>
        <div class="col-1 p-0 justify-content-end">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom right"
            [adaptivePosition]="false"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <ng-container *appHasPermission="['store_eq']">
              <button
                *ngIf="device.status === posStatus.WAITING"
                type="button"
                class="btn btn-link"
                (click)="regenerateCode(device)"
              >
                <em class="icon-reset-pin"></em>Regenerate Code
              </button>
              <button
                *ngIf="device.status === posStatus.ACTIVATED"
                type="button"
                class="btn btn-link"
                (click)="onUpdateRegisterPOS(device)"
              >
                <em class="icon-edit"></em>Edit
              </button>
              <button
                *ngIf="device.status === posStatus.ACTIVATED"
                type="button"
                class="btn btn-link"
                (click)="onDeactivate(i + 1)"
              >
                <em class="icon-deactivate"></em>Deactivate
              </button>
            </ng-container>
            <button type="button" class="btn btn-link" (click)="showHistory(i + 1)">
              <em class="icon-history"></em>Previous Setting
            </button>
          </ng-template>
        </div>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-6">
            <span class="info-text-label">Device ID:</span>
          </div>
          <div class="col-6">
            <span class="info-text-value">{{ dashDisplay | pureFn: device.deviceId:device.status }}</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <span class="info-text-label">RD Registered No.:</span>
          </div>
          <div class="col-6">
            <span class="info-text-value">{{ dashDisplay | pureFn: device?.registeredNo:device.status }}</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <span class="info-text-label">Serial No.:</span>
          </div>
          <div class="col-6">
            <span class="info-text-value">{{
              dashDisplay | pureFn: (device?.serialNo | dashDisplay):device.status
            }}</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <span class="info-text-label">Effective Date:</span>
          </div>
          <div class="col-6">
            <span class="info-text-value">{{
              dashDisplay
                | pureFn: (device?.effectiveDate | dashDisplay | amFromUtc | amLocal | dateDisplay):device.status
            }}</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <span class="info-text-label">Last Updated:</span>
          </div>
          <div class="col-6">
            <span class="info-text-value">{{
              dashDisplay
                | pureFn: (device?.registerDate | dashDisplay | amFromUtc | amLocal | dateDisplay):device.status
            }}</span>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <span class="info-text-label">Status:</span>
          </div>
          <div class="col-6">
            <span
              class="info-text-value pos-status"
              *ngIf="device.status; else none"
              [ngClass]="getColorStatus | pureFn: device.status"
            >
              {{ dashDisplay | pureFn: ('POS_STATUS.' + device.status | translate):device.status }}
            </span>
            <ng-template #none>
              <span>-</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *appHasPermission="['store_eq']">
    <div class="col-4 px-2" *ngIf="devices?.length < 5 && showAddDevice">
      <div class="container-fluid mb-3 store-device-card">
        <div class="add-device" (click)="onAddDevice()">
          + <u class="ml-1">{{ lableAddDevice }}</u>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-modal-template-container></app-modal-template-container>
