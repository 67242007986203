import {
  ApplyForProductEnum,
  OnlineCouponPageModeEnum,
  OnlineCouponStatusEnum,
  OnlineCouponTypeEnum,
  TargetOfferEnum
} from '@shared/enum/online-coupon.enum';
import { BaseReport } from '@shared/models';

export const onlineCouponStatusFilter: { value: string; label: string }[] = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const couponStatusFilter: { value: string; label: string }[] = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const isMyApprovalModel: { value: boolean; label: string }[] = [{ value: true, label: 'Yes' }];

export interface OpenOnlineCouponModalModel {
  id?: string;
  mode: OnlineCouponPageModeEnum;
  details?: {
    type: OnlineCouponTypeEnum;
    appliedProductType: ApplyForProductEnum;
    targetOffer: TargetOfferEnum;
  };
  originPage?: string;
  template?: OnlineCouponTemplateResponse;
}

export interface OnlineCouponRequest {
  docNo?: string;
  createdDate?: string;
  createdBy?: string;
  createdByName?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  lastModifiedByName?: string;
  id?: string;
  requestNo?: string;
  type?: OnlineCouponTypeEnum;
  appliedProductType?: ApplyForProductEnum;
  targetOffer?: TargetOfferEnum;
  totalValue?: number;
  itemType?: string;
  detail?: {
    name: string;
    imageUrl?: any;
    couponValue: number;
    newPrice: {
      amount: number;
      currency: string;
    };
  };
  items?: OnlineCouponItems[];
  redeemCondition?: RedeemConditionModel;
  offerCondition?: OfferConditionModel;
  appliedStoreType?: string;
  status?: OnlineCouponStatusEnum;
  requestedDate?: string;
  requestedBy?: string;
  requestedByName?: string;
  note?: string;
  notes?: [
    {
      note: string;
      createdDate: string;
      createdBy: string;
    }
  ];
  approvalFlowId?: string;
  approvalFlowNo?: string;
  currentApprovals?: string[];
  auditLogs?: null;
  version?: number;
  templateVersion?: number;
  template?: OnlineCouponTemplateResponse;
  fileName?: string;
  uploadDate?: string;
  groupName?: string;
}

export interface OnlineCouponItems {
  itemNo?: number;
  articleNo: string;
  productDisplayName?: string;
  productName: string;
  barcode: string;
  unit: string;
  unitFactor: number;
  rspIncVat: number;
  qty: number;
  compensatePerUnit?: number;
  baseUnit: string;
  unitNameTh: string;
}

export interface OfferConditionModel {
  effectiveDate: string;
  effectiveTime?: string;
  expireDate: string;
  expireTime?: string;
  totalQuota: number;
  totalQuotaUnlimited?: boolean;
  quotaPerMember: number;
  quotaPerMemberUnlimited?: boolean;
  type: string;
  refCode: string;
  supplierBranchNo: string;
  supplierCode: string;
  supplierName: string;
  referenceCode: string;
  compensatePerUnit: number;
  compensatePerCoupon: number;
}

export interface RedeemConditionModel {
  effectiveDate: string;
  effectiveTime?: string;
  expireDate: string;
  expireTime?: string;
  redeemLimitPerBill: number;
  minPurchaseAmount: {
    amount: number;
    currency: string;
  };
}

export class OnlineCouponRequestSearchCriteria {
  searchCriteria?: string;
  status?: string;
  isMyApproval?: boolean;
  couponType?: string[];
  requestedDateFrom?: string;
  requestedDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
  sortBy?: string;
  sortOrder?: string;
  page: number;
  size: number;
}

export class OnlineCouponRequestListContent {
  id: string;
  version: number;
  requestNo: string;
  couponName: string;
  status: OnlineCouponStatusEnum;
  type: OnlineCouponTypeEnum;
  offerEffectiveDate: string;
  offerExpireDate: string;
  requestedByName: string;
  requestedDate: string;
  currentApprovals: string[];
}

export class OnlineCouponRequestListResponse {
  content: OnlineCouponRequestListContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number?: number;
  first: boolean;
  numberOfElements: number;
  hasContent: boolean;
  empty: boolean;
}

export interface OnlineCouponTemplateRequest {
  couponType: string;
  appliedProductType: string;
  targetOffer: string;
}

export interface OnlineCouponTemplateResponse {
  header: string;
  body: string;
  condition: string;
  version: number;
}

export interface OnlineCouponErrorResponse {
  code: string;
  message: string;
}

export interface OnlineCouponApproveRejectRequest {
  comment: string;
  docNo: string;
}

export interface OnlineCouponApproveCancelRequest {
  docNo: string;
  comment: string;
}

export class OnlineCouponListResponse {
  content: OnlineCouponListContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number?: number;
  first: boolean;
  numberOfElements: number;
  hasContent: boolean;
  empty: boolean;
}

export interface OnlineCouponListContent {
  id: string;
  version: number;
  requestNo: string;
  couponName: string;
  docNo: string;
  status: OnlineCouponStatusEnum;
  type: OnlineCouponTypeEnum;
  targetOffer: TargetOfferEnum;
  offerEffectiveDate: string;
  offerExpireDate: string;
  redeemEffectiveDate: string;
  redeemExpireDate: string;
  requestedByName: string;
  requestedDate: string;
  createdDate: string;
  createdByName: string;
  appliedProductType: string;
  cancelled: boolean;
}

export class OnlineCouponSearchCriteria {
  searchCriteria?: string;
  status?: string;
  couponType?: string[];
  appliedProductType?: string[];
  targetOffer?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  offerDateFrom?: string;
  offerDateTo?: string;
  redeemDateFrom?: string;
  redeemDateTo?: string;
  sortBy?: string;
  sortOrder?: string;
  page: number;
  size: number;
}
export interface OnlineCoupon extends OnlineCouponRequest {
  docNo: string;
}

export class OnlineCouponExportCriteria extends BaseReport {
  offerDateFrom?: string;
  offerDateTo?: string;
  redeemDateFrom?: string;
  redeemDateTo?: string;
  appliedProductType?: string[];
  couponType?: string[];
  targetOffer?: string[];
  status?: string[];
  fileType: string;

  constructor(init?: Partial<OnlineCouponExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export interface OnlineCouponCancelRequest {
  id: string;
  comment: string;
}

export class OnlineCouponSummaryCriteria extends BaseReport {
  docNo?: string;
  offerDateFrom?: string;
  offerDateTo?: string;
  redeemDateFrom?: string;
  redeemDateTo?: string;
  couponType?: string[];
  status?: string[];
  fileType: string;

  constructor(init?: Partial<OnlineCouponSummaryCriteria>) {
    super();
    Object.assign(this, init);
  }
}
