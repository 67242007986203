export enum ToteLocationType {
  /** Store */
  STORE = 'STORE'
}

export enum ToteMovementTypeEnum {
  CLAIM = 'CLAIM',
  DAMAGE = 'DAMAGE',
  LOST = 'LOST',
  MANUAL_RETURN = 'MANUAL_RETURN',
  RECEIVE_ORDER = 'RECEIVE_ORDER',
  RECEIVE_ORDER_RE = 'RECEIVE_ORDER_RE',
  RETURN_FROM_OTHER_LOCATION = 'RETURN_FROM_OTHER_LOCATION'
}
