import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';

import { LogOutAction } from '../../../shared/store/actions/auth.actions';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { AuthUserInfoState } from '../../../shared/store/state/auth-user-info.state';

@Component({
  selector: 'app-force-change-password-left',
  templateUrl: './force-change-password-left.component.html',
  styleUrls: ['./force-change-password-left.component.scss']
})
export class ForceChangePasswordLeftComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public userInfoResult$: Observable<AuthUserInfoState>;
  private localStore: Observable<any>;

  constructor(private readonly store: Store<AppStates>) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.userInfoResult$ = this.localStore.pipe(select(selectUserInfoResult));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onCancel() {
    this.store.dispatch(new LogOutAction());
  }
}
