import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { GraphqlQueryObject } from '@shared/gql/common.gql';
import { CriteriaUtil } from '@shared/utils/criteria-util';
import { dateToStringCriteria, formatDate, generateDateUTCToTag } from '@shared/utils/date-util';

import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { DeliveryByTypeEnum } from '../../../shared/enum/delivery-by-type.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { OrderColorStatusEnum } from '../../../shared/enum/order-color-status.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { AuditLog, ErrorResponse } from '../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { OrderContent, OrderPrintCriteria, OrderSearchCriteria } from '../../../shared/models/order.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService } from '../../../shared/services';
import { GTMService } from '../../../shared/services/gtm.service';
import { MasterService } from '../../../shared/services/master.service';
import { OrderService } from '../../../shared/services/order.service';
import { StoresService } from '../../../shared/services/stores.service';
import {
  OrderHistoryRequested,
  OrderListSearchRequested,
  OrderReleaseOrderRequested,
  OrderReleaseOrderRequestedError
} from '../../../shared/store/actions/order.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { OrderState } from '../../../shared/store/reducers/order.reducers';
import {
  selectOrder,
  selectOrderAuditLogs,
  selectOrderList,
  selectOrderReleaseError
} from '../../../shared/store/selectors/order.selectors';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { OrderViewComponent } from '../order-view/order-view.component';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent extends BaseSearchComponent<OrderSearchCriteria, OrderContent, OrderState>
  implements OnDestroy {
  private localStore: Observable<any>;
  dateFormat = environment.dateFormat;
  orderTypeTag: string;
  deliveryDateTag: string;
  minDeliveryDate: Date;
  maxDeliveryDate: Date;

  orderMinDate: Date;
  orderMaxDate: Date;
  errorExport: string;

  bsModalRef: BsModalRef;

  public orderStatusList: Array<{ value: string; label: string }> = filterDropdown.orderStatusFilter;

  public orderDeliveryList: Array<{ value: string; label: string }> = filterDropdown.orderDeliveryFilter;
  public orderTypeList: Array<{ value: string; label: string }> = filterDropdown.orderingMethodFilter;
  public warehouseList: WarehouseListContent[];
  public productTypeList: Array<{ value: string; label: string }> = filterDropdown.productTypeLOV;
  public listTags = new Map();
  public hasReleasePreOrderPermission: boolean;
  public hasReleaseMemberRewardPermission: boolean;
  public hasSearchCriteria: boolean;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];
  public orderFlowList = filterDropdown.orderFlowStatusFilter;
  public crossDockSupplierList: NgOption[] = [];

  auditLogs$: Observable<AuditLog[]>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: UntypedFormBuilder,
    protected readonly modalService: BsModalService,
    protected orderService: OrderService,
    protected masterService: MasterService,
    private readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    private storesService: StoresService,
    protected gtmService: GTMService
  ) {
    super(store, modalService, selectOrderList, selectOrder, gtmService);
    super.subscribeForSaveSuccess();
  }

  doInit() {
    const query = new GraphqlQueryObject();
    query.name = MasterDataEnum.CROSS_DOCK_SUPPLIERS;
    query.defaultFields = ['code', 'name'];
    this.masterService
      .getMasterDataByNames([query])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.crossDockSupplierList = result[MasterDataEnum.CROSS_DOCK_SUPPLIERS];
      });

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new WarehouseListRequestAction('-'));
    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(data => {
      this.warehouseList = data;
    });

    this.minDeliveryDate = new Date(2019, 0, 1);
    this.maxDeliveryDate = new Date();
    this.maxDeliveryDate.setDate(this.maxDeliveryDate.getDate() + 365);
    this.orderMaxDate = new Date();
    this.orderMaxDate.setDate(this.orderMaxDate.getDate() + 365);

    this.hasReleasePreOrderPermission = this.authGuardService.checkPermission(['pe_order_m']);
    this.hasReleaseMemberRewardPermission = this.authGuardService.checkPermission(['me_order_m']);

    this.initialOrderReleaseErrorEffect();

    this.auditLogs$ = this.localStore.pipe(select(selectOrderAuditLogs));
  }

  initialOrderReleaseErrorEffect() {
    this.localStore
      .pipe(
        select(selectOrderReleaseError),
        filter(data => Boolean(data) && !this.bsModalRef),
        switchMap(() => {
          const confirmModalRef = this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: `Not allow to release order. Stock allocation is under processing.<br/> Please try again later.`
            }
          });
          return confirmModalRef.content.action;
        })
      )
      .subscribe(() => {
        this.store.dispatch(new OrderReleaseOrderRequestedError(null));
      });
  }

  doDestroy() {
    // intentionally empty
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      orderStatus: [this.orderStatusList[0].value],
      orderType: [null],
      deliveryBy: [null],
      orderDateFrom: [null],
      orderDateTo: [null],
      deliveryDateFrom: [null],
      deliveryDateTo: [null],
      warehouseCode: [null],
      orderFlow: [null],
      crossDockSupplier: [null]
    });
  }

  onChangeOrderStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      orderStatus: event.value,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  onChangeDeliveryDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDeliveryDate = new Date(value);
    } else {
      this.minDeliveryDate = new Date(2019, 0, 1);
    }
  }

  onChangeDeliveryDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDeliveryDate = new Date(value);
    } else {
      this.maxDeliveryDate = new Date();
      this.maxDeliveryDate.setDate(this.maxDeliveryDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.orderDateFrom &&
      !formValue.orderDateTo &&
      !formValue.deliveryDateFrom &&
      !formValue.deliveryDateTo &&
      !formValue.orderType &&
      !formValue.deliveryBy &&
      !formValue.warehouseCode &&
      !formValue.orderFlow &&
      !formValue.crossDockSupplier
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      orderDateFrom: dateToStringCriteria(formValue.orderDateFrom),
      orderDateTo: dateToStringCriteria(formValue.orderDateTo, false),
      deliveryDateFrom: formatDate(formValue.deliveryDateFrom, this.dateFormat),
      deliveryDateTo: formatDate(formValue.deliveryDateTo, this.dateFormat),
      orderType: this.convertArrayFilter(formValue.orderType),
      deliveryBy: this.convertArrayFilter(formValue.deliveryBy),
      warehouseCode:
        formValue.warehouseCode && formValue.warehouseCode.length > 0 ? formValue.warehouseCode.toString() : null,
      orderFlow: formValue.orderFlow && formValue.orderFlow.length > 0 ? formValue.orderFlow.toString() : null,
      crossDockSupplier:
        formValue.crossDockSupplier && formValue.crossDockSupplier.length > 0
          ? formValue.crossDockSupplier.toString()
          : null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  get isSearch(): boolean {
    return !!(this.criteriaObject.searchCriteria || this.listTags.size > 0);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.listTags.clear();

    const createdDate = generateDateUTCToTag({
      dateName: 'Created Date',
      dateFrom: this.criteriaObject.orderDateFrom,
      dateTo: this.criteriaObject.orderDateTo
    });

    if (createdDate.dateStringTag) {
      this.listTags.set('createdDate', {
        name: createdDate.dateStringTag,
        value: createdDate.dateTag,
        remove: () => this.clearFilterOrderDate()
      });
    }

    const deliveryDate = generateDateUTCToTag({
      dateName: 'Requested Delivery Date',
      dateFrom: this.criteriaObject.deliveryDateFrom,
      dateTo: this.criteriaObject.deliveryDateTo
    });

    if (deliveryDate.dateStringTag) {
      this.listTags.set('deliveryDate', {
        name: deliveryDate.dateStringTag,
        value: deliveryDate.dateTag,
        remove: () => this.clearFilterDeliveryDate()
      });
    }

    if (this.criteriaObject.orderType && this.criteriaObject.orderType.length > 0) {
      const type = this.orderTypeList
        .filter(data => {
          return this.criteriaObject.orderType.indexOf(data.value) > -1;
        })
        .map(data => data.label);

      const orderType = type.join(', ');
      this.listTags.set('orderType', {
        name: 'Order Type',
        value: `"${orderType}"`,
        remove: () => this.clearFilterOrderType()
      });
    }

    if (this.criteriaObject.deliveryBy && this.criteriaObject.deliveryBy.length > 0) {
      const delivery = this.orderDeliveryList
        .filter(data => {
          return this.criteriaObject.deliveryBy.indexOf(data.value as DeliveryByTypeEnum) > -1;
        })
        .map(data => data.label);

      const deliveryBy = delivery.join(', ');
      this.listTags.set('deliveryBy', {
        name: 'Delivery By',
        value: `"${deliveryBy}"`,
        remove: () => this.clearFilterDeliveryBy()
      });
    }

    if (this.criteriaObject.warehouseCode && this.criteriaObject.warehouseCode.length) {
      const types = this.warehouseList
        .filter(data => this.criteriaObject.warehouseCode.includes(data.code))
        .map(data => data.warehouseNameDisplay)
        .join(', ');
      this.listTags.set('warehouse', {
        name: 'Warehouse',
        value: `"${types}"`,
        remove: () => this.clearFilterWarehouse()
      });
    }

    if (this.criteriaObject.orderFlow) {
      const types = this.orderFlowList.find(data => this.criteriaObject.orderFlow === data.value);
      this.listTags.set('orderFlow', {
        name: 'Order Flow',
        value: `"${types.label}"`,
        remove: () => this.clearFilterOrderFlow()
      });
    }

    if (this.criteriaObject.crossDockSupplier) {
      const types = this.crossDockSupplierList.find(data => this.criteriaObject.crossDockSupplier === data.code);
      this.listTags.set('crossDockSupplier', {
        name: 'Cross Dock Supplier',
        value: `"${types.name}"`,
        remove: () => this.clearFilterCrossDockSupplier()
      });
    }
  }

  clearFilterOrderDate() {
    this.setFirstPage();
    this.searchForm.controls['orderDateFrom'].reset();
    this.searchForm.controls['orderDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      orderDateFrom: null,
      orderDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterDeliveryDate() {
    this.setFirstPage();
    this.searchForm.controls['deliveryDateFrom'].reset();
    this.searchForm.controls['deliveryDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      deliveryDateFrom: null,
      deliveryDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterOrderType() {
    this.setFirstPage();
    this.searchForm.controls['orderType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      orderType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterDeliveryBy() {
    this.setFirstPage();
    this.searchForm.controls['deliveryBy'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      deliveryBy: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterWarehouse() {
    this.setFirstPage();
    this.searchForm.controls['warehouseCode'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      warehouseCode: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterOrderFlow() {
    this.setFirstPage();
    this.searchForm.controls['orderFlow'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      orderFlow: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCrossDockSupplier() {
    this.setFirstPage();
    this.searchForm.controls['crossDockSupplier'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      crossDockSupplier: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearByTag($event) {
    if (this.listTags.has($event)) {
      this.listTags.get($event).remove();
    }
  }

  clearAdvanceFilter() {
    this.searchForm.controls['orderDateFrom'].reset();
    this.searchForm.controls['orderDateTo'].reset();
    this.searchForm.controls['deliveryDateFrom'].reset();
    this.searchForm.controls['deliveryDateTo'].reset();
    this.searchForm.controls['orderType'].reset();
    this.searchForm.controls['deliveryBy'].reset();
    this.searchForm.controls['warehouseCode'].reset();
    this.searchForm.controls['orderFlow'].reset();
    this.searchForm.controls['crossDockSupplier'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      orderDateFrom: null,
      orderDateTo: null,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      orderType: null,
      deliveryBy: null,
      warehouseCode: null,
      orderFlow: null,
      crossDockSupplier: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setRouteTab() {
    const hasOrderListPagePermission = this.authGuardService.checkPermission([
      'so_v',
      'or_fl_ast_m',
      'or_st_equip_m',
      'or_fl_inv_m',
      'or_fl_qty_inv_m',
      'pe_order_m',
      'me_order_m'
    ]);
    const hasOrderRequestListPagePermission = this.authGuardService.checkPermission([
      'or_v',
      'so_firstlot_m',
      'so_special_m',
      'so_app',
      'or_fl_ast_m',
      'or_fl_ast_app',
      'or_st_equip_m',
      'or_fl_inv_m',
      'or_fl_inv_app',
      'or_fl_qty_inv_m',
      'pe_order_m',
      'me_order_m'
    ]);

    this.listRoute = [];

    if (hasOrderListPagePermission) {
      this.listRoute.push({
        tabName: 'ORDER.ORDER_LIST',
        url: '/order/order-list'
      });
    }

    if (hasOrderRequestListPagePermission) {
      this.listRoute.push({
        tabName: 'ORDER_REQUEST.ORDER_REQUEST',
        url: '/order/order-request-list'
      });
    }
  }

  search(criteriaObj) {
    this.hasSearchCriteria = CriteriaUtil.hasCriteria(this.criteriaObject);
    this.store.dispatch(new OrderListSearchRequested(criteriaObj, false));
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  goToView(order: OrderContent) {
    const title = 'View Order';
    const orderNo = order.orderNo;
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          OrderViewComponent,
          {
            orderNo,
            title
          },
          false
        )
      }
    });
    this.bsModalRef.onHide.pipe(take(1)).subscribe(() => {
      this.bsModalRef = null;
    });
  }

  onPrintPdf(order: OrderContent) {
    const param: OrderPrintCriteria = {
      id: order.id,
      format: 'pdf',
      locale: 'th'
    };

    this.orderService.printPdfOrder(param).subscribe({
      next: response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, this.getGeneratedFileName(order.orderNo));
      },
      error: error => {
        this.alertErrorModal(error.error);
      }
    });
  }

  onReleaseOrder(order: OrderContent) {
    if (!order) {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to release Order No. <strong>&quot;${order.orderNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, release'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new OrderReleaseOrderRequested({
              orderNo: order.orderNo,
              version: order.version,
              reason: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  getGeneratedFileName(orderNo: string) {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.downloadPdf.timeFormat);
    const formattedName = orderNo ? orderNo.replace('/', '_') : '';
    return `${formattedName}_${formattedDate}`;
  }

  showHistory(order: OrderContent) {
    this.store.dispatch(new OrderHistoryRequested(order.orderNo));
    const initialState = {
      title: 'History',
      historyHeader: `Order No.: ${order.orderNo}`,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code !== '00001') {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  isCanPrintPDF(status: string) {
    return ['NEW_ORDER', 'PROCESSING', 'PARTIAL_DELIVERED', 'CLOSED', 'DELIVERED'].includes(status);
  }

  getColorStatus(status: string) {
    return OrderColorStatusEnum[status];
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }

  transformWareHouseName(warehouseName: string, deliveryByName: string) {
    if (deliveryByName === 'SUPPLIER') {
      return '(None)';
    }
    return warehouseName;
  }

  canReleasePreOrder(orderContent: OrderContent): boolean {
    if (orderContent.orderType.toUpperCase() === 'PRE_ORDER') {
      return orderContent.status === 'AWAITING_ALLOCATION' && this.hasReleasePreOrderPermission;
    }

    if (orderContent.orderType.toUpperCase() === 'MEMBER_REWARD') {
      return orderContent.status === 'AWAITING_ALLOCATION' && this.hasReleaseMemberRewardPermission;
    }

    return false;
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }
}
