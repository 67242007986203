<div class="left-section left-section-wrapper">
  <h2 class="forgot-section-header">{{ 'TD_NEST' | translate }}</h2>
  <h4>
    {{ 'USER.RESET_PASSWORD' | translate }}
  </h4>
  <strong>{{
    'FORCE_CHANGE_PASSWORD.HELLO_FULL_NAME'
      | translate
        : {
            firstName: (userInfoResult$ | async)?.firstName,
            lastName: (userInfoResult$ | async)?.lastName
          }
  }}</strong>
  <p>
    {{ 'FORCE_CHANGE_PASSWORD.WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD' | translate }}
  </p>
  <div>
    <button type="button" class="btn btn-standard" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
