import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, skip, take } from 'rxjs/operators';

import { environment as env, environment } from '../../../../../environments/environment';
import { emailValidator } from '../../../custom-validators/email-validator';
import { merchantDuplicatedValidator } from '../../../custom-validators/merchant-duplicated-validator';
import { FileModuleEnum } from '../../../enum/file-url.enum';
import { TDStoreValidatorTypeEnum } from '../../../enum/merchant-validator-type.enum';
import { RequestSectionEnum } from '../../../enum/request-section.enum';
import { RequestStatusEnum } from '../../../enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../enum/request-step.enum';
import { TDStorePage } from '../../../enum/td-store-page.enum';
import { MerchantRequestViewResponse, StoreProfile } from '../../../models';
import { deliveryBy4Wheels } from '../../../models/list-value/list-key-value.model';
import { MerchantRequestService } from '../../../services/merchant-request.service';
import { AppStates } from '../../../store/state/app.states';
import { getSelectByPage } from '../../../utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../utils/td-store-workflow-util';
import { DeliveryTimesComponent } from './delivery-times/delivery-times.component';

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.scss']
})
export class StoreProfileComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  @ViewChild('deliveryTimes') deliveryTimes: DeliveryTimesComponent;
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() saved: boolean;
  @Input() requestId: string;
  @Input() mode: RequestPageModesEnum;
  @Input() page: TDStorePage;
  @Input() listOfChange: any;

  onStoreCodeAsyncValidateStatus$ = new BehaviorSubject<string>(null);

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly merchantService: MerchantRequestService,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil
  ) {
    super();
  }

  private localStore: Observable<any>;
  public listOfValue: {};
  public dateFormat = environment.dateFormat;
  public bsConfig: BsDatepickerConfig;

  public merchantPage = TDStorePage;
  public fileModule = FileModuleEnum;

  type: RequestTypeEnum;
  private step: RequestStepEnum;
  // @ts-ignore
  private status: RequestStatusEnum;

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.bsConfig = ({
      dateInputFormat: this.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      useUtc: true
    } as unknown) as BsDatepickerConfig;

    this.parentForm.addControl('storeProfile', this.fb.array([]));

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.storeProfile.push(this.createStoreProfile());
    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setStoreProfileValue();
    }

    this.listOfValue = { deliveryBy4Wheels };

    ((this.parentForm.controls.storeProfile as FormArray).at(0) as FormGroup)
      .get('storeCode')
      .statusChanges.pipe(untilComponentDestroyed(this))
      .subscribe(status => {
        this.onStoreCodeAsyncValidateStatus$.next(status);
      });
  }

  isShowDuplicatedError(control: AbstractControl): string {
    const isDuplicated = control.errors ? control.errors.duplicated : false;
    return ((this.saved || this.submitted) && control.errors) || isDuplicated ? 'is-invalid' : '';
  }

  isUniqueValidator(controlName): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null && this.storeProfile.controls.length > 1) {
        const isDuplicated = this.storeProfile.controls
          .map((formGroup: AbstractControl | any) => formGroup.controls[controlName])
          .filter(formControl => formControl !== control)
          .every((formControl: UntypedFormControl) => formControl.value === control.value);

        return isDuplicated ? { duplicated: isDuplicated } : null;
      }
      return null;
    };
  }

  setStoreProfileValue() {
    this.storeProfile.disable();

    this.merchantRequestView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(data => data !== null)
    );

    const merchantRequestViewEffect$ = this.merchantRequestView$.pipe(
      map(response => response),
      filter(value => value !== null)
    );

    merchantRequestViewEffect$.pipe(skip(1), untilComponentDestroyed(this)).subscribe({
      next: value => {
        const terminateDate = value.merchantInfo.storeProfile[0].terminateDate;
        this.storeProfile
          .at(0)
          .get('terminateDate')
          .setValue(terminateDate ? moment(terminateDate, [env.dateFormat, moment.ISO_8601]).toDate() : null);
      }
    });

    merchantRequestViewEffect$.pipe(take(1)).subscribe(value => {
      this.type = value.type || RequestTypeEnum.EDIT;
      this.step = value.step || RequestStepEnum.EDIT_PROFILE;
      this.status = value.status || RequestStatusEnum.DRAFT;

      if (
        value.merchantInfo &&
        this.tdStoreWorkflowUtil.isShowSection(this.type, this.page, this.step, RequestSectionEnum.STORE_PROFILE) &&
        value.merchantInfo.storeProfile.length > 0
      ) {
        value.merchantInfo.storeProfile.forEach((store, i) => {
          if (!this.storeProfile.at(i)) {
            this.storeProfile.push(this.createStoreProfile());
          }
          const storeProfilePatched = this.createStoreProfileValue(store);

          this.storeProfile
            .at(i)
            .get('storeCode')
            .clearAsyncValidators();

          this.storeProfile
            .at(i)
            .get('storeCode')
            .updateValueAndValidity({ onlySelf: true });

          this.storeProfile
            .at(i)
            .get('storeName')
            .clearAsyncValidators();

          this.storeProfile
            .at(i)
            .get('storeName')
            .updateValueAndValidity({ onlySelf: true });

          this.storeProfile
            .at(i)
            .get('branchName')
            .clearAsyncValidators();

          this.storeProfile.controls[i].patchValue(storeProfilePatched);
        });
      }

      this.setStoreProfileCtrl(this.type, this.page, this.step, this.mode);
    });
  }

  createStoreProfileValue(store: StoreProfile) {
    return {
      ...store,
      openDate: store.openDate ? moment(store.openDate, [env.dateFormat, moment.ISO_8601]).toDate() : null,
      terminateDate: store.terminateDate
        ? moment(store.terminateDate, [env.dateFormat, moment.ISO_8601]).toDate()
        : null
    };
  }

  createStoreProfile() {
    const regExpBranchNo = /^\d{5}$/;
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];

    const storeForm: UntypedFormGroup = this.fb.group({
      openDate: initialNullRequired,
      storeCode: [
        { value: null, disabled: false },
        {
          validators: [Validators.required, this.isUniqueValidator('storeCode')],
          asyncValidators: [merchantDuplicatedValidator(TDStoreValidatorTypeEnum.STORE_CODE, this.merchantService)],
          updateOn: 'blur'
        }
      ],
      storeName: [
        { value: null, disabled: false },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ],
      branchName: [
        { value: null, disabled: false },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ],
      email: [{ value: null, disabled: false }, [Validators.required, Validators.maxLength(100), emailValidator()]],
      taxNo: [{ value: null, disabled: false }, [Validators.required, Validators.minLength(13)]],
      branchNo: [{ value: null, disabled: false }, [Validators.pattern(regExpBranchNo), Validators.required]],
      terminateDate: [{ value: null, disabled: true }]
    });

    return storeForm;
  }

  toggleEditStoreProfile() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;
    this.setStoreProfileCtrl(this.type, this.page, this.step, this.mode);
  }

  setStoreProfileCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(localPage)
      ? RequestSectionEnum.PROFILE
      : RequestSectionEnum.STORE_PROFILE;

    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(localType, localPage, localStep, editSection);

    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.storeProfile.enable();

      this.storeProfile.controls.forEach(store => {
        if (store instanceof UntypedFormGroup) {
          if (this.type !== RequestTypeEnum.EDIT) {
            store.controls.storeCode.setAsyncValidators([
              merchantDuplicatedValidator(
                TDStoreValidatorTypeEnum.STORE_CODE,
                this.merchantService,
                this.requestId,
                this.page
              )
            ]);
            store.controls.storeCode.updateValueAndValidity();
          }

          this.disableFields();
        }
      });
    } else {
      this.storeProfile.disable();
    }
  }

  disableFields() {
    const storeProfileCtrl = this.storeProfile.at(0);
    if (this.step === RequestStepEnum.EDIT_PROFILE) {
      storeProfileCtrl.get('storeCode').disable();
      storeProfileCtrl.get('storeName').disable();
      storeProfileCtrl.get('taxNo').disable();
      storeProfileCtrl.updateValueAndValidity();
    }

    storeProfileCtrl.get('terminateDate').disable();

    if (storeProfileCtrl.get('terminateDate').value) {
      storeProfileCtrl.get('openDate').disable();
    }
  }

  isRequestTypeEdit(requestType: RequestTypeEnum) {
    return requestType === RequestTypeEnum.EDIT;
  }
  get storeProfile() {
    return this.parentForm.get('storeProfile') as UntypedFormArray;
  }
}
