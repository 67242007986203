import { createSelector } from '@ngrx/store';

import * as fromPromotionCreateResponseState from '../reducers/promotion-create.reducers';
import * as fromPromotionRequestState from '../reducers/promotion-request.reducers';
import { AppStates } from '../state/app.states';

const selectPromotionRequestState = (state: AppStates) => state.promotionRequest;
const selectPromotionCreateState = (state: AppStates) => state.promotionCreate;
const promotionThemeState = (state: AppStates) => state.promotionTheme;

export const selectAllPromotionRequestList = createSelector(
  selectPromotionRequestState,
  fromPromotionRequestState.selectAll
);

export const selectPromotionRequestList = createSelector(
  selectPromotionRequestState,
  (state: fromPromotionRequestState.PromotionRequestState) => state
);

export const selectPromotionRequestListCriteria = createSelector(selectPromotionRequestState, state => {
  return state.criteriaObject;
});

export const selectPromotionSubmitRequest = createSelector(
  selectPromotionRequestState,
  (state: fromPromotionRequestState.PromotionRequestState) => state
);

export const selectPromotionCreated = createSelector(
  selectPromotionCreateState,
  (state: fromPromotionCreateResponseState.PromotionCreateResponseState | null) => state
);
export const selectPromotionSubmit = createSelector(
  selectPromotionCreateState,
  (state: fromPromotionCreateResponseState.PromotionCreateResponseState | null) => state
);

export const selectPromotionThemeComing = createSelector(promotionThemeState, state => {
  return state.themesComing ? state.themesComing : null;
});

export const selectPromotionThemeActive = createSelector(promotionThemeState, state => {
  return state.themesActive ? state.themesActive : null;
});

export const selectAddPromotionTheme = createSelector(promotionThemeState, state => {
  return state.isAddSuccess;
});

export const selectAddPromotionThemeError = createSelector(promotionThemeState, state => {
  return state.error;
});

export const selectPromotionItems = createSelector(selectPromotionCreateState, state => {
  return state.requestPromotion ? state.requestPromotion.promotionItems : null;
});

export const selectCoPromotionItems = createSelector(selectPromotionCreateState, state => {
  return state.requestPromotion ? state.requestPromotion.coPromotionItems : null;
});

export const selectRequestPromotionData = createSelector(selectPromotionCreateState, state => {
  return state.requestPromotionData ? state.requestPromotionData : null;
});
