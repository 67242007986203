import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { PosRegisterModes } from '../../../../shared/enum/store.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { Device, ErrorResponse } from '../../../../shared/models';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { StoreService } from '../../../../shared/services/store.service';
import { AppStates } from '../../../../shared/store/state/app.states';
import { StoreByIdRequestAction } from '../../store/store.actions';
import { STORE_DEVICE } from '../enum/store';
import { EMPTY, Observable, Subject, catchError, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-register-pos',
  templateUrl: './register-pos.component.html',
  styleUrls: ['./register-pos.component.scss']
})
export class RegisterPOSComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @Input() storeNo: string;
  @Input() merchant: string;
  @Input() mode: PosRegisterModes;
  @Input() data: Device;
  @Input() device: STORE_DEVICE;
  @Input() isRegenerate: boolean;
  @Input() runningNo: number;
  @Input() version: number;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public isSavedDeliveryDetails = false;
  public bsConfig: BsDatepickerConfig;
  public form: UntypedFormGroup;
  public scheduleConfig: BsDatepickerConfig;
  public submitted: boolean;
  public viewMode: boolean;
  public title: string;
  private destroy$ = new Subject<void>();
  
  constructor(
    public bsModalRef: BsModalRef,
    protected readonly modalService: BsModalService,
    public fb: UntypedFormBuilder,
    protected readonly store: Store<AppStates>,
    private readonly storeService: StoreService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.setModeTitle();
    this.createForm();
    this.setInitialValue();
  }

  setModeTitle() {
    switch (this.mode) {
      case PosRegisterModes.CREATE:
        this.title = `Generate Registration Code`;
        break;
      case PosRegisterModes.EDIT:
        this.title = `Update Device`;
        break;
      case PosRegisterModes.REGENERATE:
        this.title = `Regenerate Registration Code`;
        break;
    }
  }

  createForm() {
    const hexaDemicalRegex = '^(0x|0X)?[a-fA-F0-9]+$';
    this.form = this.fb.group({
      deviceId: [
        { value: null, disabled: false },
        [Validators.required, Validators.maxLength(16), Validators.pattern(hexaDemicalRegex)]
      ],
      registeredNo: [{ value: null, disabled: false }, [Validators.required, Validators.maxLength(20)]],
      serialNo: [{ value: null, disabled: false }, [Validators.required, Validators.maxLength(50)]],
      type: [this.device],
      runningNo: [this.runningNo]
    });
  }

  setInitialValue() {
    if (this.mode !== PosRegisterModes.EDIT && !this.data) {
      return;
    }

    this.form.get('deviceId').setValue(this.data.deviceId);
    this.form.get('serialNo').setValue(this.data.serialNo);
    this.form.get('registeredNo').setValue(this.data.registeredNo);

    if (this.mode === PosRegisterModes.EDIT) {
      this.form.get('deviceId').disable();
      this.form.get('serialNo').disable();
    }
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  confirm() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.decline();
    let POSRegisteredData = this.form.getRawValue();

    if (this.mode === PosRegisterModes.EDIT) {
      POSRegisteredData = { ...POSRegisteredData, version: this.version };
      const updatePOSRegistrationCodeService$ = this.storeService.updatePOSRegistrationCode(
        { storeNo: this.storeNo },
        POSRegisteredData
      );
      this.subscribeRegistrationResult(updatePOSRegistrationCodeService$);
    } else {
      POSRegisteredData = { ...POSRegisteredData, isRegenerate: this.isRegenerate, version: this.version };
      const generateRegistrationCodeService$ = this.storeService.generatePOSRegistrationCode(
        { storeNo: this.storeNo },
        POSRegisteredData
      );

      this.subscribeRegistrationResult(generateRegistrationCodeService$);
    }
  }

  subscribeRegistrationResult(service$: Observable<any>) {
    service$
      .pipe(
        switchMap(response => this.alertSuccessModal(response).pipe(takeUntil(this.destroy$))),
        catchError(error => {
          this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
          this.alertErrorModal(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  alertSuccessModal(registrationCode: string) {
    let message = `Your Registration Code is <strong>${registrationCode}</strong>`;

    if (this.mode === PosRegisterModes.EDIT) {
      message = `Device has been updated.`;
    }

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState: {
        title: 'Success',
        message
      }
    });

    return alertModal.content.action.pipe(
      switchMap(result => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StoreByIdRequestAction({
              merchant: { merchant: this.merchant },
              storeNo: { storeNo: this.storeNo }
            })
          );

          this.bsModalRef.hide();
        }

        return EMPTY;
      })
    );
  }

  alertErrorModal(errorResponse: any) {
    const error: ErrorResponse = errorResponse.error;
    let errorMessage = '';

    const alertModal = message => {
      const initialState = {
        title: 'Failed',
        message
      };

      this.modalService.show(AlertModalComponent, {
        initialState
      });
    };

    if (error.code === '07001') {
      errorMessage = this.getDuplicatedErrorMessage(error);
      alertModal(errorMessage);
    } else if (error.code === '00001') {
      errorMessage = this.translate.instant(`ERROR_CODE.${error.code}`);
      alertModal(errorMessage);
    } else {
      errorMessage = this.translate.instant(error.translateKey, { context: error.message });
      alertModal(errorMessage);
    }
  }

  getDuplicatedErrorMessage(error: ErrorResponse): string {
    const regex = /^\[(.*)\]/g;
    const matched = regex.exec(error.message);

    if (matched && matched[1]) {
      const fieldsMessage = matched[1]
        .replace(/\w+/g, field => {
          return this.translate.instant('STORE.POS_REGISTERED.' + field);
        })
        .replace(/,/g, ', ');

      return this.translate.instant('ERRORS.DUPLICATED_FIELD', { value: fieldsMessage });
    }
    return null;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
  }
}
