import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { StoreService } from '@shared/services/store.service';
import { SharedModule } from '@shared/shared.module';

import { ImportOrderScheduleModalComponent } from './store-components/import-order-schedule/import-order-schedule.component';
import { StoreListComponent } from './store-list/store-list.component';
import { StoreRequestListComponent } from './store-request-list/store-request-list.component';
import { StoreRequestComponent } from './store-request/store-request.component';
import { StoreEffects } from './store/store.effects';
import { storeModuleReducers } from './store/store.reducers';
import { TerminateStoreModalComponent } from './terminate-store/terminate-store-modal/terminate-store-modal.component';
import { TerminateStoreComponent } from './terminate-store/terminate-store.component';
import { POSTerminalComponent } from './view-store/pos-terminal/pos-terminal.component';
import { RegisterPOSComponent } from './view-store/register-pos/register-pos.component';
import { ViewStoreComponent } from './view-store/view-store.component';
import { StoreDeviceComponent } from './view-store/store-device/store-device.component';
import { PureFnModule } from '../../shared/pipes/pure-fn/pure-fn.module';
import { AlertModalTemplateModule } from '../../shared/components/alert-modal-template/alert-modal-template.module';

@NgModule({
  declarations: [
    ImportOrderScheduleModalComponent,
    StoreListComponent,
    StoreRequestComponent,
    StoreRequestListComponent,
    ViewStoreComponent,
    RegisterPOSComponent,
    POSTerminalComponent,
    TerminateStoreModalComponent,
    TerminateStoreComponent,
    StoreDeviceComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('store', storeModuleReducers.store),
    EffectsModule.forFeature([StoreEffects]),
    PureFnModule,
    AlertModalTemplateModule
  ],
  providers: [StoreService]
})
export class TDStoreModule {}
