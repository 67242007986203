import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { STORE_DEVICE } from '../enum/store';

@Component({
  selector: 'app-store-device',
  templateUrl: './store-device.component.html',
  styleUrls: ['./store-device.component.scss']
})
export class StoreDeviceComponent implements OnInit {
  @Input() data: { storeNo?: string; merchant?: string };
  @Input() version: number;
  
  env = environment.viewStore;
  device = STORE_DEVICE;
  constructor() {}

  ngOnInit(): void {}
}
