import * as numeral from 'numeral';

import { environment as env } from '../../../../environments/environment';
import { ToteMovementTypeEnum } from '../../../pages/tote-information/enum/tote-information.enum';
import { BillPaymentStatusEnum } from '../../enum/bill-payment-status.enum';
import { AdjustTypeEnum, LocationTypeEnum } from '../../enum/manage-stock.emun';
import { SelectedPromotionStoreEnum, SelectedTaskAssignmentStoreEnum } from '../../enum/promotion.enum';
import { StockMovementTypeEnum } from '../../enum/stock-movement-type.enum';
import { selectedWarehouseConditionEnum, selectedWarehouseEnum } from '../../enum/warehouse-enum';
import { ProductMasterSelectStatusEnum } from '../product-master.model';
import {
  AssortmentDeliveryMethodEnum,
  AssortmentFreshLiteTypeEnum,
  ProductStatusEnum
} from '../request-assortment.model';

export type optionDropdown = { value: string; label: string };
export const productsMasterStatus = [
  { value: '', label: 'All Status' },
  { value: ProductMasterSelectStatusEnum.NEW, label: 'New Product' },
  { value: ProductMasterSelectStatusEnum.SELECTED, label: 'Copy Completed' },
  { value: ProductMasterSelectStatusEnum.UNUSED, label: `${env.theme.toLocaleUpperCase()} Unused` }
];

export const productsMasterType = [
  { value: 'CJ', label: 'CJ' },
  { value: 'TD', label: 'TD' }
];

export const barcodeSizeLOV = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' }
];
export const barcodeStatusLOV = [
  { value: '', label: '(None)' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const barcodeStatusWithoutNoneValueLOV = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
  { value: 'MARK_DELETE', label: 'Mark Delete' }
];

export const supplierLOV = [{ value: 'CJ', label: 'CJ Express Group Co., Ltd.' }];

export const productTierLOV = [
  { value: 'Core', label: 'Core' },
  { value: 'Non-Core', label: 'Non-Core' }
];

export const highMarginLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const productStandardLOV = [
  { value: 'TISI', label: 'TISI' },
  { value: 'FDA', label: 'FDA' },
  { value: 'N/A', label: 'N/A' }
];

export const productLevelList = [
  { value: 'Family', label: 'Family' },
  { value: 'Class', label: 'Class' },
  { value: 'Sub Class', label: 'Sub Class' },
  { value: 'Article', label: 'Article' }
];

export const exclusiveProductLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const cbgProductLOV = [
  { value: 'CBG', label: 'CBG' },
  { value: 'OEM', label: 'OEM' },
  { value: 'Other', label: 'Other' }
];

export const stockStatusLOV = [
  { value: 'In stock', label: 'In stock' },
  { value: 'Out of stock', label: 'Out of stock' }
];

export const yesNoLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const vatLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const applyLOV = [
  { value: false, label: 'Not Apply' },
  { value: true, label: 'Apply' }
];

export const productRecommendLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const shippingMethodLOV = [
  { value: 'Flow thru', label: 'Flow thru' },
  { value: 'Direct to Store', label: 'Direct to Store' }
];

export const productAssortmentStatus = [
  { value: '', label: 'All Status' },
  { value: ProductStatusEnum.SELECT, label: 'Select' },
  { value: ProductStatusEnum.NEW, label: 'New' },
  { value: ProductStatusEnum.SEASONAL, label: 'Seasonal' },
  { value: ProductStatusEnum.ACTIVE, label: 'Active' },
  { value: ProductStatusEnum.OUT_OF_STOCK, label: 'Out of Stock' },
  { value: ProductStatusEnum.HOLD_BUY, label: 'Hold (buy)' },
  { value: ProductStatusEnum.HOLD_BUY_SELL, label: 'Hold (buy,sell)' },
  { value: ProductStatusEnum.CLEAR, label: 'Clear' },
  { value: ProductStatusEnum.CLEAR_PROMOTION, label: 'Clear Promotion' },
  { value: ProductStatusEnum.RECALL, label: 'Recall' },
  { value: ProductStatusEnum.DELETE, label: 'Delist' }
];

export const catalogStatus = [
  { value: 'I', label: 'Inactive' },
  { value: 'A', label: 'Active' }
];

export const productGradingLOV = [
  { value: 5, label: 'A' },
  { value: 4, label: 'B' },
  { value: 3, label: 'C' },
  { value: 2, label: 'D' }
];

export const articleTypeLOV = [
  { value: 'M', label: 'Merchandise' },
  { value: 'N', label: 'Non Merchandise' }
];

export const productTypeLOV = [
  { value: 'INVENTORY', label: 'Inventory' },
  { value: 'FIX_ASSET', label: 'Fix Asset' },
  { value: 'STORE_USE', label: 'Store Use' }
];

export const productTypeNonMerLOV = [
  { value: 'FIX_ASSET', label: 'Fix Asset' },
  { value: 'STORE_USE', label: 'Store Use' }
];

export const productStatusLOV = [
  { value: '', label: '(None)' },
  { value: ProductStatusEnum.SELECT, label: 'Select' },
  { value: ProductStatusEnum.NEW, label: 'New' },
  { value: ProductStatusEnum.SEASONAL, label: 'Seasonal' },
  { value: ProductStatusEnum.ACTIVE, label: 'Active' },
  { value: ProductStatusEnum.OUT_OF_STOCK, label: 'Out of Stock' },
  { value: ProductStatusEnum.HOLD_BUY, label: 'Hold (buy)' },
  { value: ProductStatusEnum.HOLD_BUY_SELL, label: 'Hold (buy, sell)' },
  { value: ProductStatusEnum.CLEAR, label: 'Clear' },
  { value: ProductStatusEnum.CLEAR_PROMOTION, label: 'Clear Promotion' },
  { value: ProductStatusEnum.RECALL, label: 'Recall' },
  { value: ProductStatusEnum.DELETE, label: 'Delist' }
];

export const assortmentRequestStatusLOV = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const pickingUnitLOV = [
  { value: 'CAR', label: 'CAR' },
  { value: 'PAC', label: 'PAC' },
  { value: 'BOX', label: 'BOX' },
  { value: 'BAG', label: 'BAG' },
  { value: 'PC', label: 'PC' }
];

export const classMarkupFilter = [
  { value: '', label: 'All Status' },
  { value: 'Y', label: 'Price Markup (yes)' },
  { value: 'N', label: 'Price Markup (no)' }
];

export const scheduleFilter = [
  { value: '', label: 'All Schedule' },
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' }
];

export const merchantStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' }
];

export const merchantRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'WA', label: 'Awaiting Approval' },
  { value: 'WOS', label: 'Awaiting Order Schedule' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const rewardCatalogStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AS', label: 'Awaiting Schedule' },
  { value: 'A', label: 'Active' },
  { value: 'E', label: 'Expired' },
  { value: 'C', label: 'Cancelled' }
];

export const rewardConditionStatusFilter = [
  { value: 'ALL', label: 'All' },
  { value: 'POINT_ONLY', label: 'Point Only' },
  { value: 'POINT_AND_CASH', label: 'Point + Cash' }
];

export const rewardStoreStatusFilter = [
  { value: 'ALL_STORE', label: 'All Stores' },
  { value: 'SELECT_BY_STORE', label: 'Select by Store' }
];

export const rewardCatalogRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'W', label: 'Awaiting Approval' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const requestTypeFilter = [
  { value: 'N', label: 'New' },
  { value: 'E', label: 'Edit' }
];
export const newRequestTypeFilter = [
  { value: 'NEW', label: 'New' },
  { value: 'EDIT', label: 'Edit' }
];

export const baseCurrency = [{ value: 'THB', label: 'THB - Thai Baht' }];
export const deliveryBy4Wheels = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];
export const gender = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' }
];
export const deliveryMethod = [
  { value: AssortmentDeliveryMethodEnum.SUPPLIER, label: 'Supplier (Direct to Store)' },
  { value: AssortmentDeliveryMethodEnum.TD, label: 'TD Warehouse' }
];

export const storeAssortmentEditType = [
  { value: 'ADD', label: 'Add' },
  { value: 'DELETE', label: 'Delete' }
];

export const storeAssortmentRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_VERIFICATION', label: 'Awaiting Verification' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'AWAITING_UPDATE_ASSORTMENT', label: 'Awaiting Update Assortment' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const keywordList = [
  { value: 'STORE_CODE', label: 'Store Code' },
  { value: 'STORE_NAME', label: 'Store Name' },
  { value: 'ARTICLE_NO', label: 'Article No.' },
  { value: 'BARCODE', label: 'Barcode' },
  { value: 'PRODUCT_NAME', label: 'Product Name' }
];

// Order List Page
export const orderStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'NEW_ORDER', label: 'New' },
  { value: 'AWAITING_ALLOCATION', label: 'Awaiting Allocation' },
  { value: 'AWAITING_CREATE_GO', label: 'Awaiting Create GO' },
  { value: 'GO_CREATED', label: 'GO Created' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'PARTIAL_DELIVERED', label: 'Partial Delivered' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'CANCELED_BY_SUPPLIER', label: 'Cancelled' },
  { value: 'CLOSED', label: 'Closed' },
  { value: 'REJECTED_BY_ERP', label: 'Rejected by ERP' }
];

export const orderRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'ASSIGN_STORE_EQUIPMENT', label: 'Assign Store Equipment' },
  { value: 'ASSIGN_QUANTITY', label: 'Assign Quantity' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const orderingMethodLOV = [
  {
    value: '["FIRST_LOT_ORDER","SPECIAL_REQUEST"]',
    label: 'First Lot, Special Request'
  },
  {
    value: '["FIRST_LOT_ORDER","SPECIAL_REQUEST","STORE_REPLENISHMENT"]',
    label: 'First Lot, Special Request, Store Replenishment'
  },
  { value: '["FRESH_LITE"]', label: 'Fresh Lite' }
];

export const orderingMethodAssortmentFilter = [
  { value: 'FIRST_LOT_ORDER', label: 'First Lot' },
  { value: 'SPECIAL_REQUEST', label: 'Special Request' },
  { value: 'STORE_REPLENISHMENT', label: 'Store Replenishment' },
  { value: 'FRESH_LITE', label: 'Fresh Lite' }
];

export const orderingMethodFilter = [
  { value: 'FIRST_LOT_ORDER', label: 'First Lot' },
  { value: 'FRESH_LITE', label: 'Fresh Lite' },
  { value: 'MEMBER_REWARD', label: 'Member Reward' },
  { value: 'PRE_ORDER', label: 'Pre Order' },
  { value: 'SPECIAL_REQUEST', label: 'Special Request' },
  { value: 'STORE_REPLENISHMENT', label: 'Store Replenishment' }
];

export const deliveryOrderType = [
  { value: 'FIRST_LOT_ORDER', label: 'First Lot' },
  { value: 'MEMBER_REWARD', label: 'Member Reward' },
  { value: 'PRE_ORDER', label: 'Pre Order' },
  { value: 'SPECIAL_REQUEST', label: 'Special Request' },
  { value: 'STOCK_TRANSFER', label: 'Stock Transfer' },
  { value: 'STORE_REPLENISHMENT', label: 'Store Replenishment' }
];

export const groupOrderTypeFilter = [
  { value: 'MEMBER_REWARD', label: 'Member Reward' },
  { value: 'PRE_ORDER', label: 'Pre Order' }
];

export const orderSubtypeAdvancedFilter = [
  { value: 'INVENTORY', label: 'Inventory' },
  { value: 'FIX_ASSET_AND_STORE_USE', label: 'Fix Asset & Store Use' },
  { value: 'AD_HOC', label: 'Ad-hoc' },
  { value: 'REPLENISH', label: 'Replenish' }
];

export const orderSubtypeFilter = [
  { value: 'INVENTORY', label: 'Inventory', permission: 'or_fl_inv_m' },
  { value: 'FIRST_LOT_LEGACY', label: 'First Lot (Legacy)', permission: 'so_firstlot_m' },
  { value: 'FIX_ASSET_AND_STORE_USE', label: 'Fix Asset & Store Use', permission: 'or_fl_ast_m' }
];

export const orderSubtypeSpecialFilter = [
  { value: 'AD_HOC', label: 'Ad-hoc' },
  { value: 'REPLENISH', label: 'Replenish' }
];

export const orderDeliveryFilter = [
  { value: 'TD', label: 'Warehouse' },
  { value: 'SUPPLIER', label: 'Supplier' }
];
// End Order List Page

export const deliveryOrderStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'NEW', label: 'New' },
  { value: 'PICKING', label: 'Picking' },
  { value: 'LOADED', label: 'Loaded' },
  { value: 'DISPATCHED', label: 'Dispatched' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'AWAITING_CANCELLED', label: 'Awaiting Cancelled' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const shipmentStatusList = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING', label: 'Awaiting' },
  { value: 'AWAITING_DELETED', label: 'Awaiting Deleted' },
  { value: 'DISPATCHED', label: 'Dispatched' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const listStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const listStoreAssortmentStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'HOLD', label: 'Hold' }
];

export const listScheduleTypeFilter = [
  { value: 'REPLENISH', label: 'Replenish' },
  { value: 'PRE_ORDER', label: 'Pre Order' }
];

export const storeStepFilter = [
  { value: 'P', label: 'Profile' },
  { value: 'F', label: 'First Lot Order' },
  { value: 'C', label: 'Created Store' }
];

export const storeRequestTypeFilter = [
  { value: 'N', label: 'New' },
  { value: 'E', label: 'Edit' }
];

export const claimFromToteList = [
  { value: 'TD_WAREHOUSE', label: 'Warehouse' },
  { value: 'LOGISTIC_PROVIDER', label: 'Logistic Provider' }
];

export const claimFromList = [
  { value: 'TD_WAREHOUSE', label: 'Warehouse' },
  { value: 'SUPPLIER', label: 'Supplier' },
  { value: 'LOGISTIC_PROVIDER', label: 'Logistic Provider' }
];

export const claimRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'WAITING', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' }
];

export const promotionRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'W', label: 'Awaiting Approval' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const promotionStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AS', label: 'Awaiting Schedule' },
  { value: 'A', label: 'Active' },
  { value: 'E', label: 'Expired' },
  { value: 'C', label: 'Cancelled' }
];

export const promotionStatusFilterExport = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const storeRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'WA', label: 'Awaiting Approval' },
  { value: 'WOS', label: 'Awaiting Order Schedule' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const taskRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'W', label: 'Awaiting Approval' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const taskAssignmentStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_RELEASE', label: 'Awaiting Release' },
  { value: 'RELEASED', label: 'Released' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const responseAssignmentStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'NO_RESPONSE', label: 'No Response' },
  { value: 'AWAITING_VERIFY', label: 'Awaiting Verification' },
  { value: 'SENT_BACK', label: 'Sent Back' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const promotionBy = [
  { value: 'SUPPLIER', label: 'Supplier' },
  { value: 'TD', label: 'TD Tawandang' }
];

export const promotionType = [
  { value: '1', label: 'แถม 1' },
  { value: '2', label: 'แถม 1 (บังคับซื้อหลายชิ้น)' },
  { value: '3', label: 'ราคาพิเศษ' },
  { value: '4', label: 'ราคาพิเศษ (บังคับซื้อหลายชิ้น)' } // Disabled { value: '5', label: 'ซื้อคู่ถูกกว่า' }
];

//  remove Jira : BO-1443
export const prStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'AWAITING_CREATE_PO', label: 'Awaiting Create PO' },
  { value: 'COMPLETED', label: 'PR Completed' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const poStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_SEND_PO', label: 'Awaiting Send PO' },
  { value: 'AWAITING_SUPPLIER_RESPONSE', label: 'Awaiting Supplier Response' },
  { value: 'AWAITING_GR', label: 'Awaiting GR' },
  { value: 'GR_COMPLETED', label: 'GR Completed' },
  { value: 'CLOSED', label: 'Closed PO' },
  { value: 'AWAITING_CLOSED', label: 'Awaiting Closed PO' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'AWAITING_CANCELLED', label: 'Awaiting Cancelled' }
];

export const grStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'RECEIVED', label: 'Received' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const prTypeFilter = [
  { value: 'Z9', label: 'Z9' },
  { value: 'Z9_EDIT', label: 'Z9-Edit' },
  { value: 'WAREHOUSE', label: 'Warehouse' },
  { value: 'HOT_PRICE', label: 'Hot Price' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const createPrTypeFilter = [
  { value: 'WAREHOUSE', label: 'Warehouse' },
  { value: 'HOT_PRICE', label: 'Hot Price' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const dayIndexList = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' }
];

export const timeRangeTwentyFour = (needEndOfDate = true, needNanoSec = false) => {
  const t: any[] = [];

  for (let i = 0; i < 24; i++) {
    const s = numeral(i).format('00') + ':00';
    if (needNanoSec) {
      t.push({ value: `${s}:00.000'`, label: s });
    } else {
      t.push({ value: s, label: s });
    }
  }

  if (needEndOfDate) {
    if (needNanoSec) {
      t.push({ value: '23:59:59.999', label: '23:59' });
    } else {
      t.push({ value: '23:59', label: '23:59' });
    }
  }

  return t;
};

export const articleTypeList = [
  { value: '', label: 'All ArticleType' },
  { value: 'M', label: 'Merchandise' },
  { value: 'N', label: 'Non Merchandise' }
];

export const prArticleTypeList = [
  { value: 'M', label: 'Merchandise' },
  { value: 'N', label: 'Non Merchandise' }
];

export const purchaseRequestTypeLOV = [
  { value: 'Z9', label: 'Z9' },
  { value: 'Z9_EDIT', label: 'Z9-Edit' },
  { value: 'WAREHOUSE', label: 'Warehouse' },
  { value: 'HOT_PRICE', label: 'Hot Price' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const portalModuleList = [
  { value: '', label: 'All Task' },
  { value: 'CLAIM', label: 'Claim' },
  { value: 'HOLD_ASSORTMENT', label: 'Hold Assortment' },
  { value: 'MERCHANT', label: 'Merchant' },
  { value: 'COUPON_SETTING_REQUEST', label: 'Online Coupon' },
  { value: 'ORDER', label: 'Order' },
  { value: 'POINT_SETTING', label: 'Point Setting' },
  { value: 'PRODUCT', label: 'Product' },
  { value: 'PROMOTION', label: 'Promotion' },
  { value: 'PURCHASE_CONDITION', label: 'Purchase Condition' },
  { value: 'PURCHASE_REQUEST', label: 'Purchase Request' },
  { value: 'TASK_ASSIGNMENT_RESPONSE', label: 'Response' },
  { value: 'REFUND', label: 'Refund' },
  { value: 'REWARD', label: 'Reward' },
  { value: 'REWARD_CATALOG', label: 'Member Reward Catalog' },
  { value: 'SHELF_FIX_ASSET_REQUEST', label: 'Shelf Fix Asset' },
  { value: 'SHELF_INVENTORY_REQUEST', label: 'Shelf Inventory' },
  { value: 'STOCK_TRANSFER', label: 'Stock Transfer' },
  { value: 'STORE', label: 'Store' },
  { value: 'STORE_ASSORTMENT', label: 'Store Assortment' },
  { value: 'TASK_ASSIGNMENT', label: 'Task' },
  { value: 'TOTE', label: 'Tote' },
  { value: 'VOUCHER', label: 'Voucher' },
  { value: 'RETURN_TO_WAREHOUSE', label: 'Return to Warehouse' }
];

export const campaignList = [
  { code: 'COMPLEMENTARY', name: 'Complementary' },
  { code: 'MIN_PURCHASE', name: 'Minimum Purchase' },
  { code: 'NEW_STORE', name: 'New Store' }
].filter(item => (env.prefixCompany === 'CJX' ? item.code !== 'MIN_PURCHASE' : item));

export const supplierGroupRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const supplierStoreRequestStatus = [
  { value: 'ADD', label: 'Add' },
  { value: 'EDIT', label: 'Edit' },
  { value: 'DELETE', label: 'Delete' }
];

export const supplierNameFilter = [
  { value: true, label: 'Completed' },
  { value: false, label: 'Blank' }
];

export const voucherRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'W', label: 'Awaiting Approval' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const voucherStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AS', label: 'Awaiting Schedule' },
  { value: 'A', label: 'Active' },
  { value: 'E', label: 'Expired' },
  { value: 'C', label: 'Cancelled' }
];

export const seasonalAdsStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AS', label: 'Awaiting Schedule' },
  { value: 'A', label: 'Active' },
  { value: 'E', label: 'Expired' },
  { value: 'C', label: 'Cancelled' }
];

export const supplierGroupListStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const supplierListStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const selectedSupplierFilter = [
  { value: true, label: 'Completed' },
  { value: false, label: 'Incompleted' }
];

export const shelfRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'AWAITING_FIRST_LOT', label: 'Awaiting First Lot Quantity' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const shelfInventoryStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const shelfFixAssetRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const selectedActive = [
  { value: '', label: 'All Status' },
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' }
];

export const selectedPromotionStore = [
  { value: SelectedPromotionStoreEnum.ALL_STORE, label: 'All Stores' },
  { value: SelectedPromotionStoreEnum.SELECT_BY_STORE, label: 'Select by Store' }
];

export const selectedTaskAssignmentStore = [
  { value: SelectedTaskAssignmentStoreEnum.ALL_STORE, label: 'All Stores' },
  { value: SelectedTaskAssignmentStoreEnum.SELECT_BY_STORE, label: 'Select by Store' }
];

export const selectedWarehouseType = [{ code: selectedWarehouseEnum.PRE_ORDER, nameEn: 'TD Warehouse' }];

export const selectedWarehouseCondition = [
  { value: selectedWarehouseConditionEnum.CHECK_STOCK, label: 'Check Stock' },
  { value: selectedWarehouseConditionEnum.CHECK_QUOTA, label: 'Check Quota' }
];

export const promoteTag = [
  { value: 'NEW', label: 'New' },
  { value: 'BEST_SELLER', label: 'Best Seller' },
  { value: 'NA', label: 'N/A' }
];

export const freshLiteTypeLOV = [
  { value: AssortmentFreshLiteTypeEnum.KEEP_STOCK, label: 'Keep Stock' },
  { value: AssortmentFreshLiteTypeEnum.EXPENSE, label: 'Expense' }
];

export const locationType = [
  { value: LocationTypeEnum.WAREHOUSE, label: 'Warehouse', regex: /stkadj_m_([a-zA-Z]{2}[0-9]{1,3})$/ },
  { value: LocationTypeEnum.STORE, label: 'Store', regex: /^stkadj_m_sto$/ }
];

export const stockType = [
  { value: '1', label: 'Normal Price' },
  { value: '2', label: 'Special Price' }
];

export const compensateType = [
  { value: 'PROMOTION', label: 'Promotion' },
  { value: 'VOUCHER', label: 'Voucher' }
];

export const adjustTypeList = [
  { value: AdjustTypeEnum.CLAIM, label: 'Claim', allowedLocationType: [LocationTypeEnum.STORE] },
  {
    value: AdjustTypeEnum.DESTROY,
    label: 'Destroy',
    allowedLocationType: [LocationTypeEnum.WAREHOUSE, LocationTypeEnum.STORE]
  },
  { value: AdjustTypeEnum.INTERNAL_USE, label: 'Internal Use', allowedLocationType: [LocationTypeEnum.WAREHOUSE] },
  {
    value: AdjustTypeEnum.INVENTORY_COUNT,
    label: 'Inventory Count',
    allowedLocationType: [LocationTypeEnum.WAREHOUSE, LocationTypeEnum.STORE]
  },
  { value: AdjustTypeEnum.PURCHASE, label: 'Purchase', allowedLocationType: [LocationTypeEnum.WAREHOUSE] },
  { value: AdjustTypeEnum.RECEIVE_ORDER, label: 'Receive Order', allowedLocationType: [LocationTypeEnum.STORE] },
  {
    value: AdjustTypeEnum.RETURN_TO_SUPPLIER,
    label: 'Return to Supplier',
    allowedLocationType: [LocationTypeEnum.STORE, LocationTypeEnum.WAREHOUSE]
  },
  {
    value: AdjustTypeEnum.RETURN_TO_WAREHOUSE,
    label: 'Return to Warehouse',
    allowedLocationType: [LocationTypeEnum.WAREHOUSE]
  },
  {
    value: AdjustTypeEnum.TRANSFER,
    label: 'Transfer',
    allowedLocationType: [LocationTypeEnum.STORE, LocationTypeEnum.WAREHOUSE]
  },
  { value: AdjustTypeEnum.VOID_RECEIPT, label: 'Void Receipt', allowedLocationType: [] },
  { value: AdjustTypeEnum.WAREHOUSE_SALE, label: 'Warehouse Sale', allowedLocationType: [LocationTypeEnum.WAREHOUSE] }
];

export const adjustStockStatusList = [
  { value: '', label: 'All Status' },
  { value: 'NEW', label: 'New' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'FAILED', label: 'Failed' }
];

export const salesType = [
  { value: 'MEMBER_REWARD', label: 'Member Reward' },
  { value: 'NORMAL', label: 'Normal' },
  { value: 'PRE_ORDER', label: 'Pre Order' },
  { value: 'PROMOTION', label: 'Promotion' },
  { value: 'REDEEM', label: 'Redeem' }
];

export const fileType = [
  { value: 'XLSX', label: 'Excel File (.xlsx)' },
  { value: 'CSV', label: 'Text File (.csv)' }
];

export const profitSharingStatus = [
  { value: '', label: 'All Status' },
  { value: 'POSITIVE', label: 'Positive' },
  { value: 'NEGATIVE', label: 'Negative' }
];

export const z8ParameterStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' }
];

export const z8ParameterRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'COMPLETED', label: 'Completed' }
];

export const billPaymentStatusFilter = [
  { value: BillPaymentStatusEnum.NO_DATA, label: 'No Data' },
  { value: BillPaymentStatusEnum.AWAITING_PAYMENT, label: 'Awaiting Payment' },
  { value: BillPaymentStatusEnum.PAID, label: 'Paid' },
  { value: BillPaymentStatusEnum.NO_OUTSTANDING_BALANCE, label: 'No Outstanding Balance' },
  { value: BillPaymentStatusEnum.AWAITING_CONFIRM, label: 'Awaiting Confirmation' },
  { value: BillPaymentStatusEnum.ADJUST_CONFIRMED, label: 'Paid (Adjust Amount)' }
];

export const stockTransferRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const stockTransferStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'NEW', label: 'New' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'AWAITING_GR', label: 'Awaiting GR' },
  { value: 'AWAITING_CLOSED', label: 'Awaiting Closed' },
  { value: 'CLOSED', label: 'Closed' },
  { value: 'REJECTED_BY_ERP', label: 'Rejected by ERP' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const stockTransferGrStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'RECEIVED', label: 'Received' },
  { value: 'COMPLETED', label: 'Completed' }
];

export const groupOrderStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'CANCELED_BY_SUPPLIER', label: 'Cancelled' },
  { value: 'CLOSED', label: 'Closed' }
];

export const dateThirtyOne = () => {
  const t: any[] = [];

  for (let i = 1; i <= 31; i++) {
    t.push({ value: i, label: i });
  }

  return t;
};

export const z8ParameterType = [
  { value: 'PERCENT', label: 'Percent' },
  { value: 'FIXED', label: 'Fixed' }
];

export const z8ParameterFactor = [
  {
    parameterName: 'Product Factor',
    format: '0,0.[00]',
    minValue: -999.99,
    maxValue: 999.99,
    decimals: 2,
    maxLength: 6,
    allowNegative: true
  },
  {
    parameterName: 'Weekend',
    format: '0,0.[00]',
    minValue: 1,
    maxValue: 999.99,
    decimals: 2,
    maxLength: 6,
    allowNegative: false
  },
  {
    parameterName: 'Beginning Month',
    format: '0,0.[00]',
    minValue: 1,
    maxValue: 999.99,
    decimals: 2,
    maxLength: 6,
    allowNegative: false
  },
  {
    parameterName: 'Promotion',
    format: '0,0',
    minValue: 1,
    maxValue: 99999,
    decimals: 0,
    maxLength: 5,
    allowNegative: false
  },
  {
    parameterName: 'Max Target',
    format: '0,0',
    minValue: 1,
    maxValue: 99999,
    decimals: 0,
    maxLength: 5,
    allowNegative: false
  },
  {
    parameterName: 'Rounding',
    format: '0,0.[00]',
    minValue: 0,
    maxValue: 100,
    decimals: 2,
    maxLength: 6,
    allowNegative: false
  }
];

export const dataInsightNumericTextBoxFactor = [
  {
    parameterName: 'INTEGER',
    format: '0',
    minValue: 0,
    maxValue: 9999999,
    decimals: 0,
    maxLength: 7
  },
  {
    parameterName: 'FLOAT',
    format: '0.00',
    minValue: 0,
    maxValue: 9999999.99,
    decimals: 2,
    maxLength: 10
  }
];

export const rewardRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'W', label: 'Awaiting Approval' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const rewardStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AS', label: 'Awaiting Schedule' },
  { value: 'A', label: 'Active' },
  { value: 'E', label: 'Expired' },
  { value: 'C', label: 'Cancelled' }
];

export const rewardCondition = [
  { value: 'PURCHASE_AMOUNT', label: 'Purchase Amount' },
  { value: 'PURCHASE_QUANTITY', label: 'Purchase Quantity' },
  { value: 'SUPPLIER_COUPON', label: 'Supplier Coupon' }
];

export const rewardOffer = [
  { value: 'COUPON', label: 'Coupon' },
  { value: 'GOODS', label: 'Goods' }
];

export const rewardAlwaysOffer = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const yesNoOption = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const rewardRedeemPriceOption = [
  { value: 'REDEEM', label: 'Redeem' },
  { value: 'FREE', label: 'Free' }
];

export const rewardStore = [
  { value: 'ALL_STORE', label: 'All Store' },
  { value: 'SELECT_BY_STORE', label: 'Select by Store' }
];

export const rewardApplyTo = [
  { value: 'ALL_PRODUCT', label: 'All Product' },
  { value: 'SELECT_BY_PRODUCT', label: 'Select by Product' }
];

export const responseRule = [
  { value: 'ACKNOWLEDGE', label: 'Acknowledge' },
  { value: 'QUIZ', label: 'Quiz' },
  { value: 'SURVEY', label: 'Survey' }
];

export const taskRequestItem = [
  { value: 'IMAGE', label: 'Image' },
  { value: 'VIDEO', label: 'Video' }
];

export const locationLevelWarehouseLOV = [
  { value: 'ALL_DCS', label: 'All DCs' },
  { value: 'BY_DC', label: 'By DC' }
];

export const locationAllWarehouseLOV = [{ value: 'All DCs', label: 'All DCs' }];

export const locationLevelSupplierLOV = [
  { value: 'ALL_STORES', label: 'All Stores' },
  { value: 'BY_PROVINCE', label: 'By Province' },
  { value: 'BY_STORE', label: 'By Store' }
];

export const locationAllStoresLOV = [{ value: 'All Stores', label: 'All Stores' }];

export const supplierVAT = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const ediSetting = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const TaskResponseViewed = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' }
];

export const stockMovementType = [
  {
    value: StockMovementTypeEnum.INITIAL_STOCK,
    label: 'Initial'
  },
  {
    value: StockMovementTypeEnum.ADJUST_STOCK,
    label: 'Adjust'
  },
  {
    value: StockMovementTypeEnum.RECEIVED_GR,
    label: 'Receive'
  },
  {
    value: StockMovementTypeEnum.CANCELLED_GR,
    label: 'Revert'
  },
  {
    value: StockMovementTypeEnum.DELIVERY_DO,
    label: 'On Delivery'
  },
  {
    value: StockMovementTypeEnum.GOOD_SOLD_RCPT,
    label: 'Sell'
  }
];

export const purchaseConditionRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const purchaseConditionStatusLOV = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const orderScheduleTypeLOV = [
  { value: 'TODAY', label: 'Today' },
  { value: 'ADVANCED', label: 'Advanced' }
];

export const orderScheduleTypeExportLOV = [
  { value: 'ALL', label: 'All' },
  { value: 'SELECT_DATE', label: 'Select Date' }
];

export const memberStatusLOV = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'AWAITING_DELETE', label: 'Awaiting Delete' },
  { value: 'MARK_DELETE', label: 'Mark Delete' }
];

export const customerTypeLOV = [
  { value: 'THAI', label: 'คนไทย' },
  { value: 'NON_THAI', label: 'คนต่างชาติ' }
];

// Pre Order List Page
export const preOrderStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const preOrderCatalogStatusList = [
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const pointSettingRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const pointSettingRequestTypeFilter = [
  { value: 'LINE_CONNECT', label: 'Line Connect' },
  { value: 'NEW_MEMBER', label: 'New Member Enrollment' },
  { value: 'PURCHASE_AMOUNT', label: 'Purchase Amount' },
  { value: 'SPECIAL_POINT', label: 'Special Point' }
];

export const memberExcludeItemtStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const awaitingScheduleStatusFilter = [
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const pointStatementPeriodMonth = [
  { value: '3', label: '3 Months' },
  { value: '6', label: '6 Months' },
  { value: '12', label: '12 Months' },
  { value: '24', label: '24 Months' }
];

export const MyReportStatusList = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING', label: 'Awaiting' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'FAILED', label: 'Failed' }
];

export const DataInsightNumberFilter = [
  { value: '=', label: 'Equals' },
  { value: '!=', label: 'Does Not Equal' },
  { value: '>', label: 'Greater Than' },
  { value: '>=', label: 'Greater Than or Equal To' },
  { value: '<=', label: 'Less Than or Equal To' },
  { value: '<', label: 'Less Than' },
  { value: 'between', label: 'Between' }
];

export const DataInsightDateFilter = [
  { value: '>', label: 'Date From' },
  { value: '<', label: 'Date To' },
  { value: 'between', label: 'Date' }
];

export const DataInsightAddressFilter = [
  { value: 'province', label: 'Province' },
  { value: 'district', label: 'District' },
  { value: 'subDistrict', label: 'Sub-District' }
];

export const customer360ViewTypeFilter = [
  { value: 'MY_TEMPLATE', label: 'My Template' },
  { value: 'ALL_TEMPLATE', label: 'All Template' }
];

export const dataInsightExportFileTypeList = [
  { value: 'XLSX', label: 'Excel File (.xlsx)' },
  { value: 'CSV', label: 'Text File (.csv)' }
];

export const notForSaleItemtStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const storeType = [
  { value: 'PARTNER_MODEL', label: 'Partner Model' },
  { value: 'STORE_MODEL', label: 'Store Model' }
];

export const refundMethod = [
  { value: 'AUTO_REFUND_TO_PARTNER', label: 'Auto Refund to Partner' },
  { value: 'MANUAL_REFUND_TO_CUSTOMER', label: 'Manual Refund to Customer' },
  { value: 'MANUAL_REFUND_TO_PARTNER', label: 'Manual Refund to Partner' },
  { value: 'NON_REFUND_CREDIT_TERM', label: 'Non Refund (Credit Term)' }
];

export const refundReason = [
  { value: 'REFUND_BILL', label: 'คืนเงินค่าสินค้าจากการยกเลิกบิล' },
  { value: 'APPEAL_FOR_FINE', label: 'คืนเงินการอุทธรณ์ค่าปรับ' },
  { value: 'APPEAL_FOR_STOCK_LOSS', label: 'คืนเงินการอุทธรณ์ค่าสินค้าสูญหายจากการตรวจนับ' },
  { value: 'OVER_TRANSFER', label: 'เงินที่โอนเกินมา' },
  { value: 'OTHERS_REFUND', label: 'อื่นๆ' }
];

export const refundStatus = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_AUTO_REFUND', label: 'Awaiting Auto Refund' },
  { value: 'AWAITING_MANUAL_REFUND', label: 'Awaiting Manual Refund' },
  { value: 'COMPLETED', label: 'Completed' }
];

export const preOrderFlashSaleStatus = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'UPCOMING', label: 'Upcoming' },
  { value: 'ENDED', label: 'Ended' }
];

export const memberCondition = [
  { value: 'ALL', label: 'All Members' },
  { value: 'NEWS_CONSENT', label: 'News & Update Consented Member' }
];

export const createAudienceStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_VERIFICATION', label: 'Awaiting Verification' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'FAILED', label: 'Failed' }
];

export const returnToWarehouseRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const returnToWarehouseStatusOrderFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_GR', label: 'Awaiting GR' },
  { value: 'AWAITING_CLOSED', label: 'Awaiting Closed' },
  { value: 'CLOSED', label: 'Closed' }
];

export const returnToWarehouseStatusGRFilter = [
  { value: '', label: 'All Status' },
  { value: 'RECEIVED', label: 'Received' },
  { value: 'COMPLETED', label: 'Completed' }
];

export const returnToWarehouseTypeList = [
  { value: 'CANCEL_DISPATCHED', label: 'Cancel Dispatched' },
  { value: 'RETURN_TRANSFER', label: 'Return Transfer' },
  { value: 'STORE_TERMINATE', label: 'Store Terminate' }
];

export const returnToWarehouseAwaitingMyApprovalList = [{ value: true, label: 'Yes' }];

export const toteAdjustmentStatusType = [
  { value: 'CLAIM', label: 'Claim' },
  { value: 'DAMAGE', label: 'Damage' },
  { value: 'LOST', label: 'Lost' },
  { value: 'MANUAL_RETURN', label: 'Manual Return' },
  { value: 'RETURN_FROM_OTHER_LOCATION', label: 'Return from Other Location' }
];

export const toteDisplayBy = [
  { value: 'NO', label: 'No. of Tote' },
  { value: 'ID', label: 'Tote ID' }
];

export const toteClaimFromList = [
  { value: 'LOGISTIC', label: 'Logistic Provider' },
  { value: 'STORE', label: 'Partner' }
];
export const approvedStatusList = [
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' }
];
export const onlineCouponTypeLOV = [
  { value: 'ANY_PURCHASE', label: 'Any Purchase' },
  { value: 'FREE_ITEM', label: 'Free Item' },
  { value: 'SPECIAL_PRICE_OFFER', label: 'Special Price Offer' }
];

export const onlineCouponStatus = [
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const onlineCouponApplyForProductLOV = [
  { value: 'ALL_PRODUCT', label: 'All Product' },
  { value: 'SELECTED_PRODUCT', label: 'Selected Product' }
];

export const onlineCouponTargetOfferLOV = [
  { value: 'FIRST_COME_FIRST_SERVE', label: 'First Come First Serve' },
  { value: 'NEW_LINE_MEMBER', label: 'New Line Member' }
];

export const onlineCouponItemTypeList = [{ value: 'SINGLE', label: 'Single' }];

export const holdAssortmentStatusFilter = [
  { label: 'All Status', value: '' },
  { label: 'Awaiting Verification', value: 'AWAITING_VERIFICATION' },
  { label: 'Awaiting Approval', value: 'AWAITING_APPROVAL' },
  { label: 'Awaiting Update Assortment', value: 'AWAITING_UPDATE_ASSORTMENT' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Cancelled', value: 'CANCELLED' }
];

export enum HoldAssortmentRequestTypeEnum {
  HOLD = 'HOLD',
  UNHOLD = 'UNHOLD'
}

export const holdAssortmentRequestType = [
  { value: HoldAssortmentRequestTypeEnum.HOLD, label: 'Hold' },
  { value: HoldAssortmentRequestTypeEnum.UNHOLD, label: 'Unhold' }
];

export const delistSuggestionType = [
  { value: 'TRUE', label: 'Yes' },
  { value: 'FALSE', label: 'No' }
];

export enum CancelDispatchedTypeEnum {
  SHIPPING_DAMAGE = 'SHIPPING_DAMAGE',
  RETURN_TO_WAREHOUSE = 'RETURN_TO_WAREHOUSE'
}

export const cancelDispatchedType: optionDropdown[] = [
  {
    value: CancelDispatchedTypeEnum.RETURN_TO_WAREHOUSE,
    label: 'Return to Warehouse'
  },
  {
    value: CancelDispatchedTypeEnum.SHIPPING_DAMAGE,
    label: 'Shipping Damage'
  }
];
export const cancelDispatchedTypeCrossDock: optionDropdown[] = [
  {
    value: CancelDispatchedTypeEnum.SHIPPING_DAMAGE,
    label: 'Shipping Damage'
  }
];

export const toteMovementByWarehouseStatusType = [
  { value: 'ADD', label: 'Add' },
  { value: 'DISPATCH', label: 'Dispatch' },
  { value: 'RETURN', label: 'Return' },
  { value: 'RECEIVE', label: 'Receive' },
  { value: 'TRANSFER', label: 'Transfer' },
  { value: 'MARK_DELETE', label: 'Mark Delete' },
  { value: 'INVENTORY_COUNT', label: 'Inventory Count' }
];

export const toteMovementByStoreStatusType = [
  { value: ToteMovementTypeEnum.CLAIM, label: 'Claim' },
  { value: ToteMovementTypeEnum.DAMAGE, label: 'Damage' },
  { value: ToteMovementTypeEnum.LOST, label: 'Lost' },
  { value: ToteMovementTypeEnum.MANUAL_RETURN, label: 'Manual Return' },
  { value: ToteMovementTypeEnum.RECEIVE_ORDER, label: 'Receive Order' },
  { value: ToteMovementTypeEnum.RECEIVE_ORDER_RE, label: 'Receive Order/RE' },
  { value: ToteMovementTypeEnum.RETURN_FROM_OTHER_LOCATION, label: 'Return from Other Location' }
];

export const toteDispatchExportStatusType = [
  { value: 'DISPATCHED', label: 'Dispatched' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const refundType = [
  { value: 'VOID_BILL', label: 'Void Bill' },
  { value: 'PRE_ORDER', label: 'Pre Order' },
  { value: 'REDEEM_GOODS', label: 'Redeem Goods' },
  { value: 'REFUND_CASH', label: 'Refund Cash' },
  { value: 'REFUND_POINT', label: 'Refund Point' }
];

export enum requestStoreAssortmentRequestTypeEnum {
  ADD = 'ADD',
  DELETE = 'DELETE'
}

export enum requestStoreAssortmentDocumentTypeEnum {
  SPECIAL_ASSORTMENT = 'SPECIAL_ASSORTMENT_REQUEST'
}

export enum storeAssortmentRequest {
  'ADD_SPECIAL_ASSORTMENT' = 'ADD_SPECIAL_ASSORTMENT',
  'DELETE_SPECIAL_ASSORTMENT' = 'DELETE_SPECIAL_ASSORTMENT',
  'ADD_SHELF_OPTION' = 'ADD_SHELF_OPTION',
  'DELETE_SHELF_OPTION' = 'DELETE_SHELF_OPTION'
}

export const specialAssortmentRequestType: optionDropdown[] = [
  {
    value: storeAssortmentRequest.ADD_SPECIAL_ASSORTMENT,
    label: 'Add Special Assortment'
  },
  {
    value: storeAssortmentRequest.DELETE_SPECIAL_ASSORTMENT,
    label: 'Delete Special Assortment'
  }
];
export const shelfOptionRequestType: optionDropdown[] = [
  {
    value: storeAssortmentRequest.ADD_SHELF_OPTION,
    label: 'Add Shelf Option'
  },
  {
    value: storeAssortmentRequest.DELETE_SHELF_OPTION,
    label: 'Delete Shelf Option'
  }
];

export const returnToWarehouseRequestType = [{ value: 'store_terminate', label: 'Store Terminate' }];

export const storeAssortmentRequestType: optionDropdown[] = [
  ...shelfOptionRequestType,
  ...specialAssortmentRequestType
];

export enum orderFlow {
  'KEEP_STOCK' = 'KEEP_STOCK',
  'CROSS_DOCK' = 'CROSS_DOCK'
}

export const orderFlowStatusFilter: optionDropdown[] = [
  { value: orderFlow.KEEP_STOCK, label: 'Keep Stock' },
  { value: orderFlow.CROSS_DOCK, label: 'Cross Dock' }
];

export const crossDockInvoiceReportStatusFilter: optionDropdown[] = [
  { value: 'DISPATCHED,DELIVERED,AWAITING_CANCELLED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancel Dispatched' }
];

export const toteFindType: optionDropdown[] = [
  { value: 'STORE_RENT_TOTE', label: 'ยืมลัง' },
  { value: 'LOGISTIC_DAMAGE_TOTE', label: 'ลังเสียหายจากขนส่ง' },
  { value: 'LOGISTIC_LOST_TOTE', label: 'ลังหายจากขนส่ง' },
  { value: 'STORE_DAMAGE_TOTE', label: 'ลังเสียหายจากร้านค้า' },
  { value: 'STORE_LOST_TOTE', label: 'ลังหายจากร้านค้า' }
];
