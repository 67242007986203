import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { SupplierPriceContent, SupplierPricePagination } from '@shared/models/supplier-price.model';
import { selectRequestAssortmentSupplierPriceError } from '@shared/store/selectors/request-assortment.selectors';
import { selectSupplierPrice } from '@shared/store/selectors/supplier-price.selectors';
import { AppStates } from '@shared/store/state/app.states';

import { SupplierPriceConfigService } from '../../services/supplier-price-config.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ReIndexSupplierPrice,
  SupplierPriceActionTypes,
  SupplierPriceListRequest,
  SupplierPriceListResponse,
  SupplierPriceShowSuccess,
  SupplierPricesAddMany
} from '../actions/supplier-price.actions';

@Injectable()
export class SupplierPriceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly supplierPriceConfigService: SupplierPriceConfigService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {}

  searchSupplierPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SupplierPriceListRequest>(SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_REQUEST),
      tap(action => this.logger.debug(`@Effect Supplier Price List Requested:` + JSON.stringify(action.payload))),
      switchMap(action => {
        return this.supplierPriceConfigService.searchSupplierPrice(action.payload).pipe(
          withLatestFrom(this.store.select(selectRequestAssortmentSupplierPriceError)),
          map(([response, supplierPriceError]) => {
            response.content.map((value, index) => {
              value.itemNo = response.page * response.size + index;
            });
            if (
              supplierPriceError &&
              supplierPriceError.supplierPriceConfigValidationResult &&
              supplierPriceError.supplierPriceConfigValidationResult.length
            ) {
              response.content = response.content.map(res => {
                let foundErrorItem = supplierPriceError.supplierPriceConfigValidationResult.find(itemError => {
                  return itemError.itemNo === res.itemNo;
                });
                if (foundErrorItem) {
                  const supplierNameChange = foundErrorItem.changes.find(change => {
                    return change.field === 'supplierName';
                  });

                  const branchNoChange = foundErrorItem.changes.find(change => {
                    return change.field === 'branchNo';
                  });

                  res = {
                    ...res,
                    isSupplierChange: true,
                    supplierName: supplierNameChange ? supplierNameChange['after'] : res.supplierName,
                    branchNo: branchNoChange ? branchNoChange['after'] : res.branchNo
                  };
                }
                return res;
              });
            }
            return new SupplierPriceListResponse(response);
          }),
          switchMap(supplierPriceListResponse => {
            return [new SupplierPriceShowSuccess(action.payload.showSuccess), supplierPriceListResponse];
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      })
    );
  });

  reIndexSupplierPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReIndexSupplierPrice>(SupplierPriceActionTypes.SUPPLIER_PRICE_REMOVE_ONE_SUPPLIER),
      tap(() => this.logger.debug(`@Effect Supplier Price List Reindex`)),
      concatLatestFrom(() => this.store.select(selectSupplierPrice)),
      switchMap(([_action, supplierPriceState]) => {
        let newSupplierPrices = [];
        for (let i = 0; i < supplierPriceState.ids.length; i++) {
          let supplierPrice = JSON.parse(JSON.stringify(supplierPriceState.entities[supplierPriceState.ids[i]]));
          supplierPrice = { ...supplierPrice, itemNo: i };

          newSupplierPrices.push(supplierPrice);
        }

        // supplierPriceState = { ...supplierPriceState, entities: newSupplierPrices };

        const supplierPricePagination: SupplierPricePagination = {
          page: supplierPriceState.criteriaObject.page,
          size: supplierPriceState.criteriaObject.size,
          totalElements: supplierPriceState.totalElements,
          first: true,
          numberOfElements: null,
          totalPages: supplierPriceState.totalPages,
          hasContent: true,
          last: false,
          empty: false,
          content: newSupplierPrices
        };

        return of(new SupplierPriceListResponse(supplierPricePagination));
      })
    );
  });
}
