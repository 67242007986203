import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-username-left',
  templateUrl: './forgot-username-left.component.html',
  styleUrls: ['./forgot-username-left.component.scss']
})
export class ForgotUsernameLeftComponent {
  constructor(private router: Router) {}

  onCancel() {
    this.router.navigateByUrl('/');
  }
}
