import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';

import { FilesInputComponent } from '../files-input/files-input.component';

@Component({
  selector: 'app-files-submit',
  templateUrl: './files-submit.component.html',
  styleUrls: ['./files-submit.component.scss']
})
export class FilesSubmitComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: FilesInputComponent;
  @Output() downloadTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadErrorExcel: EventEmitter<any> = new EventEmitter<any>();
  @Output() upload: EventEmitter<File> = new EventEmitter<File>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() error: EventEmitter<any> = new EventEmitter<any>();
  @Input() title: string;
  @Input() subTitle: string;
  @Input() templateName: string;
  @Input() fileTitle: string;
  @Input() loading: boolean;
  @Input() isUploadSuccess: boolean;
  @Input() confirmSubmitText = 'The data have been uploaded. Are you sure you want to submit?';
  @Input() btnSubmitLabel = 'Submit';
  @Input() isEnableFileInput: boolean;
  @Input() cssDisabled: boolean;
  @Input() id = 'profitReport';
  @Input() dataId = 'profitReport';
  public form: UntypedFormGroup;
  public errorMessage: string;
  public isFileError: boolean;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly bsModalRef: BsModalRef,
    protected readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      files: [null, Validators.required]
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onSubmit(files?: Array<File>) {
    this.loading = true;

    if (this.isUploadSuccess) {
      this.submit.emit();
    } else {
      this.upload.emit(files[0]);
    }
  }

  onClickSubmit() {
    this.loading = true;
    if (this.isUploadSuccess) {
      this.submit.emit();
    } else {
      this.upload.emit(this.form.get('files').value[0]);
    }
  }

  reset() {
    this.errorMessage = null;
    this.isUploadSuccess = false;
    this.isFileError = false;
    this.cssDisabled = false;
  }

  onDownloadTemplate() {
    this.downloadTemplate.emit();
  }

  setErrorMessage(message: string, isFileError = false) {
    this.errorMessage = message;
    this.isFileError = isFileError;
    this.error.emit();
  }

  onDownloadErrorExcel() {
    this.downloadErrorExcel.emit(this.form.value.files[0].name);
  }

  isDisabled() {
    if (this.isEnableFileInput) {
      if (!!this.errorMessage || !this.form.value.files) {
        return true;
      } else {
        return false;
      }
    }
    return !this.form.value.files || this.loading || !!this.errorMessage;
  }
}
