import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import {
  PurchaseOrder,
  PurchaseOrderCancelCloseRequestData,
  PurchaseOrderExportCriteria,
  PurchaseOrderPagination,
  PurchaseOrderPrintCriteria,
  PurchaseOrderRescheduleDeliveryRequestModel,
  PurchaseOrderSearchCriteria
} from '../models/purchase-order.model';
import { BaseService } from './base.service';

@Injectable()
export class PurchaseOrderService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.purchaseOrder;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: PurchaseOrderSearchCriteria): Observable<PurchaseOrderPagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PO,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    return this.http.get<PurchaseOrderPagination>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public getPoById(id: string): Observable<PurchaseOrder> {
    const url = this.getFullUrl(this.envService.get, {
      id
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public cancel(poCancelRequest: PurchaseOrderCancelCloseRequestData): Observable<PurchaseOrder> {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<PurchaseOrder>(url, poCancelRequest, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  public close(poCloseRequest: PurchaseOrderCancelCloseRequestData): Observable<PurchaseOrder> {
    const url = this.getFullUrl(this.envService.close);
    return this.http.post<PurchaseOrder>(url, poCloseRequest, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  public printPdfOrder(criteria: PurchaseOrderPrintCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.print, { ...criteria });
    const body = new HttpParams();

    return this.http.post<any>(url, body, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public exportPo(criteria: PurchaseOrderExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportPo);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.PO,
          path: `${this.envService.url}${this.envService.exportPo}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PO,
          route: `${this.envService.url}${this.envService.exportPo}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public rescheduleDeliveryDate(payload: PurchaseOrderRescheduleDeliveryRequestModel): Observable<PurchaseOrder> {
    const url = this.getFullUrl(this.envService.rescheduleDeliveryDate);
    return this.http.post<PurchaseOrder>(url, payload, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }
}
