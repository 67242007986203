import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertModalTemplateComponent } from './alert-modal-template.component';
import { ModalTemplateContainerComponent } from './modal-template-container/modal-template-container.component';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [AlertModalTemplateComponent, ModalTemplateContainerComponent],
  imports: [CommonModule, MomentModule, PipesModule],
  exports: [AlertModalTemplateComponent, ModalTemplateContainerComponent]
})
export class AlertModalTemplateModule {}
