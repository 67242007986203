import { Validators } from '@angular/forms';

export interface SearchCriteriaBase {
  page: number;
  size: number;
}

export interface ListResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class PermanentHoldListSearchCriteria {
  searchCriteria?: string;
  storeType?: string;
  lastModifiedDateFrom?: string;
  lastModifiedDateTo?: string;
  page: number;
  size: number;
}

export interface PermanentHoldList {
  id: string;
  holdNum: number;
  storeCode: string;
  storeCodeName: string;
  storeName: string;
  storeNo: string;
  storeType: string;
  lastModifiedDate: string;
}

export type PermanentHoldListResponse = ListResponse<PermanentHoldList>;

export class PermanentHoldViewCriteria {
  storeCodeName: string;
  storeType: string;
}

export interface HoldItemList {
  id: string;
  holdType: string;
  productName: string;
  articleNo: string;
  refNo: string;
  requestedByName: string;
  createdBy: string;
  createdDate: string;
  period: number;
  startDate: string;
  endDate: string;
}

export interface UnholdItemList extends HoldItemList {
  lastUpdatedBy: string;
  lastUpdated: string;
  reason: string;
}

export type HoldItemListResponse = ListResponse<HoldItemList>;
export type UnholdItemListResponse = ListResponse<UnholdItemList>;

export interface HoldItemListRequest extends SearchCriteriaBase {
  storeCode: string;
  held?: boolean;
}

export interface PermanentHoldReportAttribute {
  id: string;
  name: string;
  type: string;
  operator: string;
  value: string;
}

export class HoldAssortmentExportCriteria {
  reportName: string;
  fileType?: string;
  attributes: PermanentHoldReportAttribute[];
  createdDateFrom?: string;
  createdDateTo?: string;

  constructor(init?: Partial<HoldAssortmentExportCriteria>) {
    init.reportName = 'Permanent Hold';
    Object.assign(this, init);
  }
}

export class HoldAssortmentReportCriteria {
  store: string[];
  storeType: string[];
  fileType: string;
  createdDateFrom?: string | null;
  createdDateTo?: string | null;
  constructor(init?: Partial<HoldAssortmentReportCriteria>) {
    Object.assign(this, init);
  }
}
