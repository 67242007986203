<div class="full-popup-head">
  <div class="full-popup-title flex"><em class="modal-title-icon icon-td-store"></em>{{ data.title }}</div>
  <button
    type="button"
    class="btn btn-header-button"
    (click)="onLockSale()"
    *ngIf="(storeView$ | async) && hasLockSalePermission() && data.mode === requestPageModesEnum.REQUEST_VIEW"
  >
    <em class="icon-lock-pos"></em>
    {{
      (storeView$ | async)?.saleStatus && (storeView$ | async)?.saleStatus === saleStatus.DISABLE
        ? 'Unlock Sale'
        : 'Lock Sale'
    }}
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      (storeView$ | async)?.status === requestStatus.INACTIVE &&
      hasEditPermission() &&
      data.mode === requestPageModesEnum.REQUEST_VIEW
    "
    (click)="onActivateStore(data.storeNo)"
  >
    <em class="icon-activate"></em>Activate
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      (storeView$ | async)?.status === requestStatus.ACTIVE &&
      hasEditPermission() &&
      data.mode === requestPageModesEnum.REQUEST_VIEW
    "
    (click)="onDeactivateStore(data.storeNo)"
  >
    <em class="icon-deactivate"></em>Deactivate
  </button>
  <ng-container *appHasPermission="['store_terminate']">
    <ng-container *ngIf="storeView$ | async as store">
      <button
        type="button"
        class="btn btn-header-button"
        *ngIf="!store.merchantInfo.storeProfile[0]?.terminateDate && data.mode === requestPageModesEnum.REQUEST_VIEW"
        (click)="onTerminateStore(store)"
      >
        <em class="icon-terminate"></em>Terminate Store
      </button>
      <button
        type="button"
        class="btn btn-header-button"
        *ngIf="data.mode === requestPageModesEnum.REQUEST_VIEW"
        (click)="showHistory(store)"
      >
        <em class="icon-history"></em>History
      </button>
    </ng-container>
  </ng-container>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Store ID:</span>
      </div>
      <div class="info-header-value">
        <span class="pr-2">{{ (storeView$ | async)?.no | dashDisplay }}</span>
        <span
          class="badge badge-danger badge-position"
          *ngIf="(storeView$ | async)?.saleStatus === saleStatus.DISABLE"
          >Locked Sale
        </span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="status !== 'D'; else edit">
      <div class="info-header-label">
        <span>Store Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus((storeView$ | async)?.status)" class="store-status">
          {{ 'STORE.STATUS.' + (storeView$ | async)?.status | translate }}
        </span>
      </div>
    </div>
    <ng-template #edit>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Request Status:</span>
        </div>
        <div class="info-header-value">
          <span [ngClass]="getColorStatus('draft')" class="store-status">
            {{ 'STORE_REQUEST.STATUS.' + status | translate }}
          </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="information-container">
    <div class="row merchant-info">
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Merchant ID</span>
          <span class="info-header-value">
            <app-reference-document-link
              module="MERCHANT"
              [permissions]="['merchant_new_m', 'merchant_edit_m', 'merchant_app', 'merchant_v']"
              [text]="(storeView$ | async)?.merchantId | dashDisplay"
              [referenceID]="(storeView$ | async)?.merchantId | dashDisplay"
            >
            </app-reference-document-link>
          </span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Tax ID/ID card</span>
          <span class="info-header-value">{{ (storeView$ | async)?.taxId | dashDisplay }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Merchant Name</span>
          <span class="info-header-value">{{ (storeView$ | async)?.merchantName | dashDisplay }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Merchant Type</span>
          <span class="info-header-value">{{ 'STORE_TYPE.' + (storeView$ | async)?.merchantType | translate }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Contact Name</span>
          <span class="info-header-value">{{ (storeView$ | async)?.contactName | dashDisplay }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Mobile Phone</span>
          <span class="info-header-value">{{ (storeView$ | async)?.mobilePhone | dashDisplay }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-menu d-flex d-inline-flex">
    <ng-container *ngFor="let tab of storeTabList">
      <a
        class="list-tab-menu px-3 px-md-5 py-2"
        [ngClass]="{ active: isSelectedTab | pureFn: tab.value:selectedTab }"
        (click)="onSelectTabs(tab.value)"
      >
        {{ tab.label }}
      </a>
    </ng-container>
  </div>

  <div [formGroup]="storeForm" [ngClass]="{ 'display-none': selectedTab !== StoreTabs.STORE_PROFILE }">
    <!-- Store Profile -->
    <div class="row section-header justify-content-between">
      <span>Store Profile</span>
    </div>
    <app-store-profile
      #storeProfile
      [parentForm]="storeForm"
      [submitted]="submitted"
      [mode]="data.mode"
      [page]="merchantPage"
    ></app-store-profile>

    <hr class="section-divider" />
    <!-- Store Profile -->

    <!-- Store Contact -->
    <div class="row section-header justify-content-between">
      <span>Store Contact</span>
    </div>
    <app-store-contact
      #storeContact
      [parentForm]="storeForm"
      [submitted]="submitted"
      [mode]="data.mode"
      [page]="merchantPage"
    ></app-store-contact>
    <hr class="section-divider" />
    <!-- Store Contact -->

    <!-- Store Location -->
    <div class="row section-header justify-content-between">
      <span>Store Location</span>
    </div>
    <app-store-location
      #storeLocation
      [parentForm]="storeForm"
      [submitted]="submitted"
      [mode]="data.mode"
      [page]="merchantPage"
    ></app-store-location>
    <hr class="section-divider" />
    <!-- Store Location -->

    <!-- Store Condition -->
    <div class="row section-header">
      <span id="">Store Condition</span>
    </div>
    <app-store-condition
      #storeCondition
      [parentForm]="storeForm"
      [submitted]="submitted"
      [mode]="data.mode"
      [page]="merchantPage"
    >
    </app-store-condition>

    <hr class="section-divider" />
    <!-- Store Condition -->

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-12 pl-0">
            <div class="row mb-3 mr-md-0 section-header">
              <span id="ORDER_POLICY">Order Schedule (for Replenish)</span>
            </div>

            <div class="d-flex mb-3">
              <button
                type="button"
                class="btn btn-standard d-flex align-items-center"
                *appHasPermission="['merchant_order_m']"
                (click)="onUpdateOrderSchedule()"
              >
                <em class="d-inline-block icon-calendar mr-1"></em>
                Update Order Schedule
              </button>
            </div>

            <app-order-policy
              #orderPolicy
              [parentForm]="storeForm"
              [submitted]="submitted"
              [mode]="data.mode"
              [page]="merchantPage"
            ></app-order-policy>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-12">
            <div class="row mb-3 mr-md-0 section-header">
              <span id="PRE_ORDER_POLICY">Order Schedule (for Pre Order)</span>
            </div>

            <div class="d-flex mb-3">
              <button
                type="button"
                class="btn btn-standard d-flex align-items-center"
                *appHasPermission="['merchant_order_m']"
                (click)="onUpdatePreOrderSchedule()"
              >
                <em class="d-inline-block icon-calendar mr-1"></em>
                Update Order Schedule
              </button>
            </div>

            <!-- <div class="container-fluid"> -->
            <app-pre-order-policy
              #orderPreOrderPolicy
              [parentForm]="storeForm"
              [submitted]="submitted"
              [mode]="data.mode"
              [page]="merchantPage"
              [ddlPosition]="'top'"
            ></app-pre-order-policy>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-store-device
    [ngClass]="{ 'display-none': selectedTab !== StoreTabs.STORE_DEVICE }"
    [data]="data"
    [version]="version"
  >
  </app-store-device>
  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container *ngIf="requestPageModesEnum.REQUEST_EDIT === data.mode; else otherMode">
      <div class="fixed-row-bottom form-row">
        <div class="float-right" *ngIf="hasSubmitPermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditPermission() && (storeView$ | async)?.status !== requestStatus.INACTIVE" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
  <!--  <ng-container >-->
  <!--    <div class="row section-header justify-content-between">-->
  <!--      <span>POS Device</span>-->
  <!--    </div>-->
  <!--    <app-pos-terminal #posTerminal [data]="data"></app-pos-terminal>-->
  <!--    <hr class="section-divider" />-->

  <!--    <div class="row section-header justify-content-between">-->
  <!--      <span>HAND Device</span>-->
  <!--    </div>-->
  <!--    <app-pos-terminal #posTerminal [data]="data"></app-pos-terminal>-->
  <!--  </ng-container>-->
</div>

<app-terminate-store #terminateStoreModal></app-terminate-store>
