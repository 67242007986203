import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  PromotionRequestListResponse,
  PromotionRequestResponse,
  PromotionSearchCriteria,
  RequestWithComment
} from '../models';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';
@Injectable()
export class PromotionRequestService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.promotionsRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }
  searchByCriteria(criteria: PromotionSearchCriteria): Observable<PromotionRequestListResponse> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PROMOTION,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const url = this.getUrl();
    const params = this.getParams(criteria, true);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  getByRequestId(requestId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: requestId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
  createRequest(data: PromotionRequestResponse): Observable<any> {
    const url = this.getUrl();
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }
  updateByRequestId(data: PromotionRequestResponse): Observable<any> {
    const url = this.getUrl();
    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  submit(data: PromotionRequestResponse): Observable<any> {
    const url = this.getFullUrl(this.envService.requestsSubmit);
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.PROMOTION,
          path: window.location.pathname
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.PROMOTION,
          route: this.envService.requestsSubmit,
          action: MetricActionType.SUBMIT
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.PROMOTION,
          path: window.location.pathname,
          action: MetricAction.PROMOTION_SUBMIT_ITEM,
          type: data.details.promotionType,
          items: data.promotionItems.length
        }
      ]
    };

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(3000, metricsReq),
      observe: 'body'
    });
  }
  approve(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  reject(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  getPromotionById(promotionId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.get, { id: promotionId });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
  /**
   * @description For delete promotion request
   */
  delete(promotionId: string) {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: promotionId
    });
    return this.http.delete<any>(url, {
      headers: this.loaderHeaders()
    });
  }

  /**
   * @description For cancel promotion request
   */
  requestCancel(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.requestCancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  importFilePromotionStore(file): Observable<any> {
    const url = this.getFullUrl(this.envService.import);
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  onExportTemplate() {
    const url = this.getFullUrl(this.envService.exportTemplate);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  onExportStoreRequest(id: string) {
    const url = this.getFullUrl(this.envService.exportRequest, {
      id
    });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
