import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';

@Injectable()
export class GTMService extends BaseService {
  constructor(private gtmService: GoogleTagManagerService) {
    super();
  }

  addTag<T extends Object>(tag: T) {
    if (environment.enableGTM) {
      this.gtmService.pushTag(tag);
    }
  }
}
