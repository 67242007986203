import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { PromotionRequestError, PromotionRequestResponse } from '../../models';
import { PromotionAction, PromotionActionTypes } from '../actions/promotion.action';

export interface PromotionCreateResponseState extends EntityState<PromotionRequestResponse> {
  isLoading: boolean;
  isSave: boolean | null;
  requestPromotion: any;
  promotionItems: any;
  requestPromotionData: PromotionRequestResponse | null;

  result?: {
    response: PromotionRequestResponse | null;
    errorResponse: PromotionRequestError | null;
  };
}

export const adapter: EntityAdapter<PromotionRequestResponse> = createEntityAdapter<PromotionRequestResponse>();

export const initialPromotionCreateResponseState: PromotionCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isApproveRejectSuccess: false,
  isDeleteSuccess: false,
  isSave: null,
  requestPromotionData: null,
  requestPromotion: {},
  promotionItems: {}
});

export function promotionCreateReducers(
  state = initialPromotionCreateResponseState,
  action: PromotionAction
): PromotionCreateResponseState {
  switch (action.type) {
    case PromotionActionTypes.PROMOTION_CREATE_SAVE_RESPONSE:
    case PromotionActionTypes.PROMOTION_CREATE_SUBMIT_RESPONSE:
      return {
        ...state,
        isSave: PromotionActionTypes.PROMOTION_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case PromotionActionTypes.PROMOTION_CREATE_SUBMIT_ERROR:
    case PromotionActionTypes.PROMOTION_CREATE_SAVE_ERROR:
      return {
        ...state,
        isSave: PromotionActionTypes.PROMOTION_CREATE_SAVE_ERROR === action.type,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case PromotionActionTypes.PROMOTION_CREATE_RESET:
      return initialPromotionCreateResponseState;

    case PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        requestPromotionData: action.payload
      };
    case PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_RESET:
      return initialPromotionCreateResponseState;
    case PromotionActionTypes.PROMOTION_REQUEST_CANCEL_REQUESTED:
      return {
        ...state
      };
    default: {
      return state;
    }
  }
}
