import { Action } from '@ngrx/store';

import {
  ErrorResponse,
  PromotionCreateRequest,
  PromotionListResponse,
  PromotionRequestError,
  PromotionRequestListResponse,
  PromotionRequestResponse,
  PromotionSearchCriteria,
  PromotionTheme,
  RequestWithComment
} from '../../models';

export enum PromotionActionTypes {
  PROMOTION_CREATE_SAVE_REQUEST = '[Promotion-Request] Create Promotion Save Request',
  PROMOTION_CREATE_SAVE_RESPONSE = '[Promotion-Request] Create Promotion Save Response',
  PROMOTION_CREATE_SAVE_ERROR = '[Promotion-Request] Create Promotion Save Error',
  PROMOTION_CREATE_SAVE_SUCCESS = '[Promotion-Request] Create Promotion Save Success',
  PROMOTION_EDIT_SAVE_REQUEST = '[Promotion-Request] Edit Promotion Save Request',
  PROMOTION_EDIT_SAVE_RESPONSE = '[Promotion-Request] Edit Promotion Save Response',
  PROMOTION_EDIT_SAVE_ERROR = '[Promotion-Request] Edit Promotion Save Error',
  PROMOTION_EDIT_SAVE_SUCCESS = '[Promotion-Request] Edit Promotion Save Success',
  PROMOTION_CREATE_SUBMIT_REQUEST = '[Promotion-Request] Create Promotion Submit Request',
  PROMOTION_CREATE_SUBMIT_RESPONSE = '[Promotion-Request] Create Promotion Submit Response',
  PROMOTION_CREATE_SUBMIT_ERROR = '[Promotion-Request] Create Promotion Submit Error',
  PROMOTION_CREATE_SUBMIT_SUCCESS = '[Promotion-Request] Create Promotion Submit  Success',
  PROMOTION_CREATE_RESET = '[Promotion-Request] Create Promotion Reset',

  PROMOTION_REQUEST_APPROVE_REQUESTED = '[Promotion-Request] Promotion Request Approve Requested',
  PROMOTION_REQUEST_REJECT_REQUESTED = '[Promotion-Request] Promotion Request Reject Requested',

  PROMOTION_REQUEST_CANCEL_REQUESTED = '[Promotion-Request] Promotion Request Cancel Requested',

  PROMOTION_REQUEST_LIST_REQUEST = '[Promotion-Request] List Request',
  PROMOTION_REQUEST_LIST_RESPONSE = '[Promotion-Request] List Response',
  PROMOTION_REQUEST_LIST_ERROR = '[Promotion-Request] List Error',

  PROMOTION_REQUEST_DELETE_REQUESTED = '[Promotion-Request] Delete Requested',

  PROMOTION_CANCEL_REQUESTED = '[Promotion] Cancel Requested',
  PROMOTION_CANCEL_SUCCESS = '[Promotion] Cancel Success',

  PROMOTION_LIST_REQUEST = '[Promotion] List Request',
  PROMOTION_LIST_RESPONSE = '[Promotion] List Response',
  PROMOTION_LIST_ERROR = '[Promotion] List Error',

  PROMOTION_REQUEST_GET_BY_ID_REQUEST = '[Promotion-Request get request] Request promotion by id',
  PROMOTION_REQUEST_GET_BY_ID_RESPONSE = '[Promotion-Request get request] Response promotion by id',
  PROMOTION_REQUEST_GET_BY_ID_ERROR = '[Promotion-Request get request] Response promotion by id ERROR',
  PROMOTION_REQUEST_GET_BY_ID_RESET = '[Promotion-Request get request] Response promotion by id Reset',

  PROMOTION_THEME_LIST_REQUEST = '[Promotion Theme] Request Theme List',
  PROMOTION_THEME_LIST_ACTIVE_RESPONSE = '[Promotion Theme] Response Theme List Active',
  PROMOTION_THEME_LIST_COMING_RESPONSE = '[Promotion Theme] Response Theme List Coming',
  PROMOTION_THEME_DROPDOWN_REMOVE = '[Promotion Theme] Request remove Theme Dropdown',

  PROMOTION_THEME_ADD_REQUEST = '[Promotion Theme] Add Theme Request',
  PROMOTION_THEME_ADD_SUCCESS = '[Promotion Theme] Add Theme Success',
  PROMOTION_THEME_ADD_ERROR = '[Promotion Theme] Add Theme Error',
  PROMOTION_THEME_ADD_RESET = '[Promotion Theme] Add Theme Reset',

  PROMOTION_GET_BY_ID_REQUEST = '[Promotion] Request promotion by id',
  PROMOTION_GET_BY_ID_RESPONSE = '[Promotion] Response promotion by id',
  PROMOTION_GET_BY_ID_ERROR = '[Promotion] Response promotion by id ERROR',
  PROMOTION_GET_BY_ID_RESET = '[Promotion] Response promotion by id Reset'
}

export class PromotionCreateSaveRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SAVE_REQUEST;
  constructor(public payload: PromotionCreateRequest) {}
}

export class PromotionCreateSaveResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SAVE_RESPONSE;
  constructor(public payload: PromotionRequestResponse) {}
}

export class PromotionCreateSaveActionSuccess implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SAVE_SUCCESS;
  constructor(public payload: { isSave: boolean }) {}
}

export class PromotionCreateSaveErrorAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export class PromotionEditSaveRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_EDIT_SAVE_REQUEST;
  constructor(public payload: PromotionCreateRequest) {}
}

export class PromotionEditSaveResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_EDIT_SAVE_RESPONSE;
  constructor(public payload: PromotionRequestResponse) {}
}

export class PromotionEditSaveRequestActionSuccess implements Action {
  readonly type = PromotionActionTypes.PROMOTION_EDIT_SAVE_SUCCESS;
  constructor(public payload: { isSave: boolean }) {}
}

export class PromotionEditSaveErrorAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_EDIT_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export class PromotionCreateSubmitRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SUBMIT_REQUEST;

  constructor(public payload: PromotionCreateRequest) {}
}

export class PromotionCreateSubmitRequestActionSuccess implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SUBMIT_SUCCESS;
  constructor(public payload: { isSave: boolean }) {}
}

export class PromotionCreateSubmitResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: PromotionRequestResponse) {}
}

export class PromotionCreateSubmitErrorAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_SUBMIT_ERROR;

  constructor(public payload: PromotionRequestError) {}
}

export class PromotionResetAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CREATE_RESET;

  constructor() {}
}

export class PromotionThemeRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_LIST_REQUEST;

  constructor(public payload: { type: string }) {}
}

export class PromotionThemeActiveResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_LIST_ACTIVE_RESPONSE;

  constructor(public payload: PromotionTheme[]) {}
}

export class PromotionThemeComingResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_LIST_COMING_RESPONSE;

  constructor(public payload: PromotionTheme[]) {}
}

export class PromotionThemeRemoveAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_DROPDOWN_REMOVE;

  constructor() {}
}

export class PromotionThemeAddRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_ADD_REQUEST;

  constructor(public payload: PromotionTheme) {}
}

export class PromotionThemeAddSuccess implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_ADD_SUCCESS;

  constructor(public payload: PromotionTheme) {}
}

export class PromotionThemeAddError implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_ADD_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class PromotionThemeAddReset implements Action {
  readonly type = PromotionActionTypes.PROMOTION_THEME_ADD_RESET;
}

export class PromotionRequestListRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_LIST_REQUEST;

  constructor(public payload: PromotionSearchCriteria) {}
}

export class PromotionRequestListResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_LIST_RESPONSE;

  constructor(public payload: PromotionRequestListResponse) {}
}

export class PromotionRequestListErrorAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class PromotionListRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_LIST_REQUEST;

  constructor(public payload: PromotionSearchCriteria) {}
}

export class PromotionListResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_LIST_RESPONSE;

  constructor(public payload: PromotionListResponse) {}
}

export class PromotionListErrorAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class PromotionRequestGetByIdRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: { requestId: string }) {}
}

export class PromotionRequestGetByIdResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: PromotionRequestResponse) {}
}

export class PromotionRequestGetByIdErrorAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetPromotionRequestSelected implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_RESET;
}

export class PromotionApproveRequested implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_APPROVE_REQUESTED;
  constructor(public payload: RequestWithComment) {}
}

export class PromotionRejectRequested implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_REJECT_REQUESTED;
  constructor(public payload: RequestWithComment) {}
}

export class PromotionRequestCancelRequested implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_CANCEL_REQUESTED;
  constructor(public payload: RequestWithComment) {}
}

export class PromotionGetByCodeRequestAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_GET_BY_ID_REQUEST;

  constructor(public payload: { promotionCode: string }) {}
}

export class PromotionGetByIdResponseAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_GET_BY_ID_RESPONSE;

  constructor(public payload: PromotionRequestResponse) {}
}

export class PromotionGetByIdErrorAction implements Action {
  readonly type = PromotionActionTypes.PROMOTION_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetPromotionSelected implements Action {
  readonly type = PromotionActionTypes.PROMOTION_GET_BY_ID_RESET;
}
export class PromotionRequestDeleteRequest implements Action {
  readonly type = PromotionActionTypes.PROMOTION_REQUEST_DELETE_REQUESTED;
  constructor(public payload: { requestId: string }) {}
}

export class PromotionCancelRequest implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CANCEL_REQUESTED;
  constructor(public payload: RequestWithComment) {}
}

export class PromotionCancelSuccess implements Action {
  readonly type = PromotionActionTypes.PROMOTION_CANCEL_SUCCESS;
  constructor(public payload: { isSuccess: boolean }) {}
}

export type PromotionAction =
  | PromotionCreateSaveRequestAction
  | PromotionCreateSaveResponseAction
  | PromotionCreateSaveErrorAction
  | PromotionResetAction
  | PromotionCreateSubmitRequestAction
  | PromotionCreateSubmitResponseAction
  | PromotionCreateSubmitErrorAction
  | PromotionThemeRequestAction
  | PromotionThemeActiveResponseAction
  | PromotionThemeComingResponseAction
  | PromotionThemeRemoveAction
  | PromotionThemeAddRequestAction
  | PromotionThemeAddSuccess
  | PromotionThemeAddError
  | PromotionThemeAddReset
  | PromotionRequestListRequestAction
  | PromotionRequestListResponseAction
  | PromotionListRequestAction
  | PromotionListResponseAction
  | PromotionApproveRequested
  | PromotionRejectRequested
  | PromotionRequestListErrorAction
  | PromotionListErrorAction
  | PromotionRequestGetByIdRequestAction
  | PromotionRequestGetByIdResponseAction
  | PromotionRequestGetByIdErrorAction
  | PromotionCreateSubmitRequestActionSuccess
  | PromotionEditSaveRequestActionSuccess
  | PromotionCreateSaveActionSuccess
  | PromotionGetByCodeRequestAction
  | PromotionGetByIdResponseAction
  | PromotionGetByIdErrorAction
  | ResetPromotionSelected
  | PromotionRequestDeleteRequest
  | PromotionCancelRequest
  | PromotionCancelSuccess
  | PromotionRequestCancelRequested
  | ResetPromotionRequestSelected;
