import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { GTMService } from '@shared/services/gtm.service';

import { GTMEvent } from '../enum/gtm.enum';
import { GTMTagLongResponseTime } from '../models/gtm.model';

const MINIMUM_WAIT_TIME_MS = 5 * 1000;

@Injectable()
export class GtmInterceptor implements HttpInterceptor {
  GTMService = inject(GTMService);
  logger = inject(NGXLogger);

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(Date.now()).pipe(
      switchMap(time => {
        return next.handle(request).pipe(
          tap((response: HttpResponse<any>) => {
            const endTime = Date.now();
            const elapsedTime = endTime - time;
            this.logger.log(`Response for ${response.url} took ${elapsedTime} ms`);
            if (elapsedTime > MINIMUM_WAIT_TIME_MS) {
              this.GTMService.addTag({
                event: GTMEvent.LONG_RESPONSE_TIME,
                response_time: elapsedTime,
                path: response.url || request.url
              } as GTMTagLongResponseTime);
            }
          }),
          catchError(e => {
            const elapsedTime = Date.now() - time;
            this.GTMService.addTag({
              event: 'response_error',
              response_time: elapsedTime,
              path: request.url
            } as GTMTagLongResponseTime);
            return throwError(e);
          })
        );
      })
    );
  }
}
