import { Component, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';

import { ThemeService } from '@shared/services/theme/theme.service';

import { environment as env } from '../environments/environment';
import { GTMTagReport } from './shared/models/gtm.model';
import { GTMService } from './shared/services/gtm.service';
import { isDevEnv } from './shared/utils/is-dev-util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class.tier-alpha') public isAlpha = env.tier === 'alpha';
  @HostBinding('class.tier-beta') public isBeta = env.tier === 'beta';
  @HostBinding('class.tier-staging') public isStaging = env.tier === 'staging';
  @HostBinding('class.tier-chaos') public isChaos = env.tier === 'chaos';
  @HostBinding('class.tier-develop') public isDevelop = isDevEnv(env.tier);

  public environment: { [key: string]: any } = env;
  public lang: string;
  public gtmTag: GTMTagReport;

  constructor(
    private readonly logger: NGXLogger,
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private readonly gtmService: GTMService,
    private renderer2: Renderer2,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    const title = `${env.theme.toUpperCase()} Nest${env.tier !== 'prod' ? ' - ' + env.tier.toUpperCase() : ''}`;
    console.log('env.tier ', env.tier);
    this.titleService.setTitle(title);
    this.gtmTag = {
      event: 'init'
    };
    this.gtmService.addTag(this.gtmTag);

    this.lang = this.environment.defaultLanguage;
    moment.locale(this.lang);

    this.translate.addLangs(this.environment.supportedLanguages);
    this.translate.setDefaultLang(this.lang);
    this.translate.use(this.lang);

    this.logger.trace('Production value is ' + this.environment.production);
    this.logger.debug('Your tier is ' + this.environment.tier);
    this.logger.debug('Cookies of domain is ' + this.environment.cookies.domain);
    this.logger.info('Your appVersion is ' + this.environment.version);
    this.initialize();
  }

  initialize() {
    this.themeService.setTheme(env.theme, this.renderer2);
    this.updateFavicon();
  }

  updateFavicon(): void {
    const links = document.querySelectorAll('link');
    links.forEach(link => {
      let href = link.getAttribute('href');
      if (href.includes('favicon')) {
        href = href.replace('favicon', this.environment.favicon);
        link.setAttribute('href', href);
      }
    });
  }
}
