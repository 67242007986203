export enum GTMEvent {
  EXPORT_REPORT = 'export_report',
  SEARCH_INPUT = 'search_input',
  CREATE_ORDER = 'create_order',
  LONG_RESPONSE_TIME = 'long_response_time',
  USER_INFO = 'user_info'
}

export enum TagAction {
  OPEN = 'open',
  SUBMIT = 'submit',
  SUBMIT_SUCCESS = 'submit_success',
  SUBMIT_FAIL = 'submit_fail',
  VALIDATE_FAIL = 'validate_fail'
}

export enum SEARCH_PATTERN {
  DOC_NO = 'doc_no',
  PLAIN_TEXT = 'plain_text',
  NUMBER = 'number',
  ALPHANUMERIC = 'alphanumeric'
}
