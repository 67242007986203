<div class="modal-content admin-form">
  <div class="modal-header">
    <div class="pull-left text-center">
      <h5 id="modal-title" data-id="modal-title" class="modal-title">{{ title }}</h5>
      <div id="modal-sub-title" data-id="modal-sub-title" *ngIf="this.subTitle">{{ subTitle }}</div>
    </div>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label :for="id" [attr.data-id]="dataId">{{ templateName }}</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control mr-3"
            [attr.data-id]="dataId"
            [id]="id"
            placeholder="{{ templateName }}"
            disabled
          />
          <button
            type="button"
            class="btn btn-primary"
            id="btn-download"
            data-id="btn-download"
            (click)="onDownloadTemplate()"
          >
            <em class="icon-download"></em>
            {{ 'REPORTS.DOWNLOAD' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="fileInput">{{ fileTitle }}</label>
        <div class="d-flex">
          <app-files-input
            id="fileInput"
            formControlName="files"
            [size]="50000000"
            [controlName]="'files'"
            [disabled]="loading"
            [cssDisabled]="cssDisabled"
            (clearInput)="reset()"
            (showErrorMessage)="setErrorMessage($event)"
            (submit)="onSubmit($event)"
            [isEnableFileInput]="isEnableFileInput"
            class="flex-item"
            #fileInput
          ></app-files-input>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="this.errorMessage || isUploadSuccess">
      <div class="form-group col-md" *ngIf="errorMessage || (isUploadSuccess && confirmSubmitText)">
        <div class="alert-success form-group mb-0" *ngIf="isUploadSuccess && confirmSubmitText">
          <div>
            {{ confirmSubmitText }}
          </div>
        </div>
        <div
          id="errorMessage"
          data-id="errorMessage"
          class="alert-danger form-group mb-0 error-message"
          *ngIf="errorMessage"
        >
          {{ errorMessage }}
          <a class="btn btn-link upload-error-file" (click)="onDownloadErrorExcel()" *ngIf="isFileError">download</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="closeModal()" id="btCancel" data-id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="isDisabled()"
      id="btnSubmit"
      data-id="btnSubmit"
      (click)="onClickSubmit()"
    >
      {{ btnSubmitLabel }}
    </button>
  </div>
</div>
