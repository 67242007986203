import { SEARCH_PATTERN } from '../enum/gtm.enum';

export function checkStringPattern(str) {
  const patterns = {
    [SEARCH_PATTERN.DOC_NO]: /^[A-Z]{2}\d{2}-[A-Z,a-z,0-9]{8}-\d{4}(\/[A-Z]{2})?$/,
    [SEARCH_PATTERN.PLAIN_TEXT]: /^[a-zA-Z\u0E00-\u0E4E_-]+$/,
    [SEARCH_PATTERN.NUMBER]: /^\d+$/
  };

  for (const pattern in patterns) {
    if (patterns[pattern].test(str)) {
      return pattern;
    }
  }

  return SEARCH_PATTERN.ALPHANUMERIC;
}
