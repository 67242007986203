import { DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { NgImageSliderModule } from 'ng-image-slider';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxCaptureModule } from 'ngx-capture';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxMaskModule } from 'ngx-mask';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxSpinnerModule } from 'ngx-spinner';

import { GtmInterceptor } from '@shared/http-interceptors/gtm.interceptor';
import { GTMService } from '@shared/services/gtm.service';
import { RewardOfferTransactionReportService } from '@shared/services/reward-offer-transaction-report.service';
import { RewardRedeemTransactionReportService } from '@shared/services/reward-redeem-transaction-report.service';
import { StockLocationsByPermissionService } from '@shared/services/stock-locations-by-permission.service';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HmrModule } from './hmr.module';
import { ClaimEffects } from './pages/claim-request/store/claim-request/claim-request.effects';
import { ForceChangePasswordLeftComponent } from './pages/force-change-password/force-change-password-left/force-change-password-left.component';
import { ForceChangePasswordComponent } from './pages/force-change-password/force-change-password.component';
import { ForgotPasswordLeftComponent } from './pages/forgot-password/forgot-password-left/forgot-password-left.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotUsernameLeftComponent } from './pages/forgot-username/forgot-username-left/forgot-username-left.component';
import { ForgotUsernameComponent } from './pages/forgot-username/forgot-username.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginLeftComponent } from './pages/login/login-left/login-left.component';
import { LoginRightComponent } from './pages/login/login-right.component';
import { NewReportService } from './pages/new-reports/services/new-report.service';
import { DeliveryDetailsSpecialComponent } from './pages/order/order-components/delivery-details-special/delivery-details-special.component';
import { DeliveryDetailsComponent } from './pages/order/order-components/delivery-details/delivery-details.component';
import { DeliveryScheduleComponent } from './pages/order/order-components/delivery-details/delivery-schedule/delivery-schedule.component';
import { ImportOrderRequestComponent } from './pages/order/order-components/import-order-request/import-order-request.component';
import { OrderFileUploadComponent } from './pages/order/order-components/order-file-upload/order-file-upload.component';
import { OrderModalUploadComponent } from './pages/order/order-components/order-modal-upload/order-modal-upload.component';
import { SelectItemModalComponent } from './pages/order/order-components/select-item-modal/select-item-modal.component';
import { SelectSelectiveItemModalComponent } from './pages/order/order-components/select-selective-item-modal/select-selective-item-modal.component';
import { SelectShelfFixAssetModalComponent } from './pages/order/order-components/select-shelf-fix-asset-modal/select-shelf-fix-asset-modal.component';
import { SelectShelfInventoryModalComponent } from './pages/order/order-components/select-shelf-inventory-modal/select-shelf-inventory-modal.component';
import { SelectShelfModalComponent } from './pages/order/order-components/select-shelf-modal/select-shelf-modal.component';
import { SelectStoreUseModalComponent } from './pages/order/order-components/select-store-use-modal/select-store-use-modal.component';
import { StoreInfoComponent } from './pages/order/order-components/store-info/store-info.component';
import { ViewShelfDetailsComponent } from './pages/order/order-components/view-shelf-details/view-shelf-details.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { OrderRequestFixAssetComponent } from './pages/order/order-request-fix-asset/order-request-fix-asset.component';
import { OrderRequestInventoryComponent } from './pages/order/order-request-inventory/order-request-inventory.component';
import { OrderRequestListComponent } from './pages/order/order-request-list/order-request-list.component';
import { OrderRequestComponent } from './pages/order/order-request/order-request.component';
import { OrderDetailsComponent } from './pages/order/order-view/order-details/order-details.component';
import { OrderViewComponent } from './pages/order/order-view/order-view.component';
import { ReceiveSummaryComponent } from './pages/order/order-view/receive-summary/receive-summary.component';
import { ClassMarkupComponent } from './pages/price-setting/class-markup/class-markup.component';
import { StackPricingViewComponent } from './pages/price-setting/stack-pricing/stack-pricing-view/stack-pricing-view.component';
import { ProductCreateComponent } from './pages/products/product-create/product-create.component';
import { PromotionListComponent } from './pages/promotion/promotion-list/promotion-list.component';
import { PromotionModalUploadComponent } from './pages/promotion/promotion-modal-upload/promotion-modal-upload.component';
import { PromotionRequestListComponent } from './pages/promotion/promotion-request-list/promotion-request-list.component';
import { HandleThemeComponent } from './pages/promotion/promotion-request/handle-theme/handle-theme.component';
import { PromotionConditionComponent } from './pages/promotion/promotion-request/promotion-condition/promotion-condition.component';
import { PromotionDetailsComponent } from './pages/promotion/promotion-request/promotion-details/promotion-details.component';
import { PromotionItemComponent } from './pages/promotion/promotion-request/promotion-item/promotion-item.component';
import { PromotionRequestComponent } from './pages/promotion/promotion-request/promotion-request.component';
import { PromotionStoreComponent } from './pages/promotion/promotion-request/promotion-store/promotion-store.component';
import { SelectByStoreComponent } from './pages/promotion/promotion-request/select-by-store/select-by-store.component';
import { PromotionViewComponent } from './pages/promotion/promotion-view/promotion-view.component';
import { RoleEffects } from './pages/roles/stores/role/role.effects';
import { ShelfComponentsModule } from './pages/shelf/shelf-components/shelf-components.module';
import { ShelfListComponent } from './pages/shelf/shelf-list/shelf-list.component';
import { ShelfRequestListComponent } from './pages/shelf/shelf-request-list/shelf-request-list.component';
import { ShelfRequestComponent } from './pages/shelf/shelf-request/shelf-request.component';
import { ViewShelfComponent } from './pages/shelf/view-shelf/view-shelf.component';
import { Z8FactorComponent } from './pages/store-assortment/components/Z8-factor/Z8-factor.component';
import { StoreGroupService } from './pages/store-group/services/store-group.service';
import { TDStoreModule } from './pages/store/store.module';
import { StoreEffects } from './pages/store/store/store.effects';
import { SupplierPriceComponent } from './pages/supplier-price/supplier-price.component';
import { TaskResponsePermission } from './pages/task-response/task-response-permission';
import { TaskPermission } from './pages/task/task-permission';
import { UserCreateComponent } from './pages/user/user-create/user-create.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { CollapsibleHistoryComponent } from './shared/components/collapsible-history/collapsible-history.component';
import { EditInPlaceComponent } from './shared/components/edit-in-place/edit-in-place.component';
import { FilesSubmitComponent } from './shared/components/files-submit/files-submit.component';
import { HistoryComponent } from './shared/components/history/history.component';
import { HtmlViewerModalComponent } from './shared/components/html-viewer-modal/html-viewer-modal.component';
import { OtpInputComponent } from './shared/components/otp-input/otp-input.component';
import { ShelfDetailsComponent } from './shared/components/shelf-details/shelf-details.component';
import { UpdateOrderPolicyComponent } from './shared/components/td-store/order-policy/update-order-policy/update-order-policy.component';
import { TierTagComponent } from './shared/components/tier-tag/tier-tag.component';
import { AssortmentStatusDirective } from './shared/directives/assortment-status.directive';
import { DecimalPlacesDirective } from './shared/directives/decimal-places.directive';
import { DisallowSpacesDirective } from './shared/directives/disallow-spaces.directive';
import { HexadecimalOnlyDirective } from './shared/directives/hexadecimal-only.directive';
import { OtpNumberDirective } from './shared/directives/otp-number.directive';
import { PascalCaseDirective } from './shared/directives/pascal-case.directive';
import {
  CacheInterceptor,
  ErrorInterceptor,
  SpinnerLoaderInterceptor,
  TokenInterceptor
} from './shared/http-interceptors';
import { MetricInterceptor } from './shared/http-interceptors/metric-interceptor';
import { AlertModalComponent, FooterComponent, HeaderComponent, LoginComponent } from './shared/layouts';
import { AdminLayoutComponent } from './shared/layouts/admin/admin-layout.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import { ConfirmWithDateChangeModalComponent } from './shared/layouts/modals/confirm-with-date-change-modal/confirm-with-date-change-modal.component';
import { ConfirmWithOptionModalComponent } from './shared/layouts/modals/confirm-with-option-modal/confirm-with-option-modal.component';
import { DocumentViewerModalComponent } from './shared/layouts/modals/document-viewer-modal/document-viewer-modal.component';
import { ImageModalComponent } from './shared/layouts/modals/image-modal/image-modal.component';
import { SimpleInfoListModalComponent } from './shared/layouts/modals/simple-info-list-modal/simple-info-list-modal.component';
import { NavbarComponent } from './shared/layouts/navbar/navbar.component';
import { SidebarComponent } from './shared/layouts/sidebar/sidebar.component';
import { VersionTagComponent } from './shared/layouts/version-tag/version-tag.component';
import { AdditionPipe } from './shared/pipes/addition.pipe';
import { IsDevEnvPipe } from './shared/pipes/is-dev.pipe';
import { KeysPipe, PeriodDisplayPipe } from './shared/pipes/Pipes';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import { SafeHtmlModule } from './shared/pipes/safeHtml/safe-html.module';
import { SetTimeoutPipe } from './shared/pipes/set-timeout.pipe';
import {
  AuthGuardService,
  AuthService,
  BarcodeService,
  MasterDataService,
  ProductAssortmentService,
  ProductMasterService,
  ProductService,
  RequestAssortmentService
} from './shared/services';
import { AppComponentService } from './shared/services/app-component.service';
import { AppGlobalErrorHandler } from './shared/services/app-global-error-handler.service';
import { AppInitService } from './shared/services/app-init.service';
import { ArticleService } from './shared/services/article.service';
import { ClaimReportService } from './shared/services/claim-report.service';
import { ClaimService } from './shared/services/claim.service';
import { ClassMarkupService } from './shared/services/class-markup.service';
import { CommonUploadService } from './shared/services/common.upload.service';
import { DashboardService } from './shared/services/dashboard.service';
import { DeliveryOrderService } from './shared/services/delivery-order.service';
import { DestroyService } from './shared/services/destroy.service';
import { DisableButtonService } from './shared/services/disable-button.service';
import { ErrorConnectorService } from './shared/services/error-connector.service';
import { FileUrlService } from './shared/services/file-url.service';
import { FreshLiteService } from './shared/services/fresh-lite.service';
import { GoodsReceiveService } from './shared/services/goods-receive.service';
import { HoldAssortmentReportService } from './shared/services/hold-assortment-report.service';
import { ManualSupplierPriceDataService } from './shared/services/manual-supplier-price-data.service';
import { MasterService } from './shared/services/master.service';
import { MemberExcludeItemService } from './shared/services/member-exclude-item.service';
import { MemberRewardsService } from './shared/services/member-rewards.service';
import { MembershipService } from './shared/services/membership.service';
import { MerchantRequestService } from './shared/services/merchant-request.service';
import { MerchantSelectValueService } from './shared/services/merchant-select-value.service';
import { MerchantService } from './shared/services/merchant.service';
import { MovingAverageReportService } from './shared/services/moving-average-report.service';
import { MyReportService } from './shared/services/my-report.service';
import { NotifyService } from './shared/services/notify.service';
import { OnlineCouponCompensateService } from './shared/services/online-coupon-compensate.service';
import { OrderFixAssetRequestService } from './shared/services/order-fix-asset-request.service';
import { OrderInventoryRequestService } from './shared/services/order-inventory-request.service';
import { OrderRequestExportService } from './shared/services/order-request-export.service';
import { OrderRequestService } from './shared/services/order-request.service';
import { OrderStoreUseExportService } from './shared/services/order-store-use-export.service';
import { OrderStoreUseService } from './shared/services/order-store-use.service';
import { OrderService } from './shared/services/order.service';
import { PermanentHoldService } from './shared/services/permanent-hold.service';
import { PermissionsService } from './shared/services/permissions.service';
import { PointEarnAndBurnReportService } from './shared/services/point-earn-and-burn-report.service';
import { PreOrderReportService } from './shared/services/pre-order-report.service';
import { PreOrderRequestsService } from './shared/services/pre-order-requests.service';
import { PromotionCompensateReportService } from './shared/services/promotion-compensate-report.service';
import { PromotionRequestService } from './shared/services/promotion-request.service';
import { PromotionService } from './shared/services/promotion.service';
import { PurchaseOrderService } from './shared/services/purchase-order.service';
import { PurchaseProductPricesTemplateService } from './shared/services/purchase-product-prices-template.service';
import { PurchaseRequestService } from './shared/services/purchase-request.service';
import { ReceiveOrderService } from './shared/services/receive-order.service';
import { RefundTransactionConfidentialReport } from './shared/services/refund-transaction-confidential.service';
import { RoleService } from './shared/services/role.service';
import { SalesSummaryReportService } from './shared/services/sales-summary-report.service';
import { SalesTransactionConfidentialReportService } from './shared/services/sales-transaction-confidential-report.service';
import { SalesTransactionReportService } from './shared/services/sales-transaction-report.service';
import { SettingService } from './shared/services/setting.service';
import { ShelfFixAssetRequestService } from './shared/services/shelf-fix-asset-request.service';
import { ShelfFixAssetService } from './shared/services/shelf-fix-asset.service';
import { ShelfInventoryRequestService } from './shared/services/shelf-inventory-request.service';
import { ShelfInventoryService } from './shared/services/shelf-inventory.service';
import { ShelfRequestService } from './shared/services/shelf-request.service';
import { ShelfTypesService } from './shared/services/shelf-types.service';
import { ShelfService } from './shared/services/shelf.service';
import { ShipmentService } from './shared/services/shipment.service';
import { StackPricingService } from './shared/services/stack-pricing.service';
import { StockInformationService } from './shared/services/stock-information.service';
import { StockLocationsService } from './shared/services/stock-locations.service';
import { StockProductService } from './shared/services/stock-product.service';
import { StockTransferRequestService } from './shared/services/stock-trasfer-request-service';
import { StoreDropdownService } from './shared/services/store-dropdown.service';
import { StoreExportService } from './shared/services/store-export.service';
import { StoreLocationReportService } from './shared/services/store-location-report.service';
import { StoreReportService } from './shared/services/store-report.service';
import { StoreRequestService } from './shared/services/store-request.service';
import { StoresService } from './shared/services/stores.service';
import { SupplierCouponReconcileReportService } from './shared/services/supplier-coupon-reconcile-report.service';
import { SupplierPriceConfigService } from './shared/services/supplier-price-config.service';
import { SupplierService } from './shared/services/supplier.service';
import { TaskAssignmentRequestService } from './shared/services/task-assignment-request.service';
import { TaskAssignmentResponseService } from './shared/services/task-assignment-response.service';
import { TaskAssignmentService } from './shared/services/task-assignment.service';
import { TdAssortmentService } from './shared/services/td-assortment-service';
import { VoucherReportService } from './shared/services/voucher-report.service';
import { WarehouseService } from './shared/services/warehouse.service';
import { SharedModule } from './shared/shared.module';
import { AuthEffects } from './shared/store/effects/auth.effects';
import { BillToMasterEffects } from './shared/store/effects/bill-to-master.effects';
import { ClassMarkupEffects } from './shared/store/effects/class-markup.effects';
import { Customer360Effects } from './shared/store/effects/customer360.effects';
import { DashboardEffects } from './shared/store/effects/dashboard.effects';
import { FirstLotOrderEffects } from './shared/store/effects/first-lot-order.effects';
import { ForgotPasswordEffects } from './shared/store/effects/forgot-password.effects';
import { ForgotUsernameEffects } from './shared/store/effects/forgot-username.effects';
import { FreshLiteEffects } from './shared/store/effects/fresh-lite.effects';
import { LayoutEffects } from './shared/store/effects/layout.effects';
import { MasterDataEffects } from './shared/store/effects/master-data.effects';
import { MenuEffects } from './shared/store/effects/menu.effects';
import { OrderFixAssetRequestEffects } from './shared/store/effects/order-fix-asset-request.effects';
import { OrderInventoryRequestEffects } from './shared/store/effects/order-inventory-request.effects';
import { OrderRequestEffects } from './shared/store/effects/order-request.effects';
import { OrderSelectItemEffects } from './shared/store/effects/order-select-item.effects';
import { OrderEffects } from './shared/store/effects/order.effects';
import { ProductMasterEffects } from './shared/store/effects/product-master.effects';
import { PromotionEffects } from './shared/store/effects/promotion.effects';
import { RequestAssortmentEffects } from './shared/store/effects/request-assortment.effects';
import { ResponseAssignmentEffects } from './shared/store/effects/response-assignment.effects';
import { SettingEffects } from './shared/store/effects/setting.effects';
import { ShelfRequestEffects } from './shared/store/effects/shelf-request.effects';
import { ShelfEffects } from './shared/store/effects/shelf.effects';
import { ShelfSelectFixAssetEffects } from './shared/store/effects/shlef-select-fix-asset.effects';
import { StackPricingEffects } from './shared/store/effects/stack-pricing.effects';
import { StockProductEffects } from './shared/store/effects/stock-product.effects';
import { StoreDropdownEffects } from './shared/store/effects/store-dropdown.effects';
import { StoreRequestEffects } from './shared/store/effects/store-request.effects';
import { SupplierPriceEffects } from './shared/store/effects/supplier-price.effects';
import { SupplierEffects } from './shared/store/effects/supplier.effects';
import { TaskAssignmentEffects } from './shared/store/effects/task-assignment.effects';
import { TaskRequestEffects } from './shared/store/effects/task-request.effects';
import { TdAssortmentEffects } from './shared/store/effects/td-assortment.effects';
import { UserEffects } from './shared/store/effects/user-info.effects';
import { WarehouseMasterEffects } from './shared/store/effects/warehouse-master-effects';
import { WarehouseEffects } from './shared/store/effects/warehouse.effects';
import { appReducers } from './shared/store/reducers/app.reducers';
import { clearState } from './shared/store/reducers/meta-reducers/clear-state';
import { CookiesUtil } from './shared/utils/cookies-util';
import { ExcelUtil } from './shared/utils/excel-util';
import { PermissionsUtil } from './shared/utils/permissions-util';
import { TDStoreWorkflowUtil } from './shared/utils/td-store-workflow-util';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const loggerModuleOption = () => {
  if (environment.production) {
    return NgxLoggerLevel.OFF;
  } else if (
    ['develop', 'redseries', 'greenapple', 'galaxy', 'khamoo', 'khaohom', 'integration'].includes(environment.tier)
  ) {
    return NgxLoggerLevel.TRACE;
  } else {
    return NgxLoggerLevel.INFO;
  }
};

export function InitializeAppFactory(appInitService: AppInitService) {
  return appInitService.load();
}

console.log('GTM enable ===> ', environment.enableGTM);
console.log('GTM ID ===> ', environment.GTM_ID);

// TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    LoginRightComponent,
    LoginLeftComponent,
    HomeComponent,
    AdminLayoutComponent,
    NavbarComponent,
    SidebarComponent,
    ProductCreateComponent,
    PeriodDisplayPipe,
    AdditionPipe,
    SetTimeoutPipe,
    IsDevEnvPipe,
    AlertModalComponent,
    ConfirmWithOptionModalComponent,
    ConfirmWithDateChangeModalComponent,
    ClassMarkupComponent,
    HistoryComponent,
    StoreInfoComponent,
    DecimalPlacesDirective,
    DisallowSpacesDirective,
    StackPricingViewComponent,
    AssortmentStatusDirective,
    SupplierPriceComponent,
    UpdateOrderPolicyComponent,
    OrderListComponent,
    OrderViewComponent,
    OrderDetailsComponent,
    OrderModalUploadComponent,
    OrderFileUploadComponent,
    ReceiveSummaryComponent,
    OrderRequestListComponent,
    OrderRequestComponent,
    OrderRequestFixAssetComponent,
    OrderRequestInventoryComponent,
    SelectItemModalComponent,
    SelectStoreUseModalComponent,
    DeliveryDetailsComponent,
    DeliveryScheduleComponent,
    DeliveryDetailsSpecialComponent,
    SelectShelfModalComponent,
    SelectSelectiveItemModalComponent,
    SelectShelfFixAssetModalComponent,
    SelectShelfInventoryModalComponent,
    ShelfDetailsComponent,
    ViewShelfDetailsComponent,
    PromotionListComponent,
    PromotionRequestComponent,
    PromotionRequestListComponent,
    PromotionItemComponent,
    PromotionViewComponent,
    PromotionDetailsComponent,
    PromotionConditionComponent,
    PromotionStoreComponent,
    SelectByStoreComponent,
    HexadecimalOnlyDirective,
    ImageModalComponent,
    HandleThemeComponent,
    LoginLayoutComponent,
    ForgotUsernameComponent,
    ForgotUsernameLeftComponent,
    ForgotPasswordComponent,
    ForgotPasswordLeftComponent,
    KeysPipe,
    OtpNumberDirective,
    OtpInputComponent,
    UserListComponent,
    UserCreateComponent,
    ShelfRequestListComponent,
    ShelfRequestComponent,
    ShelfListComponent,
    ViewShelfComponent,
    VersionTagComponent,
    UserEditComponent,
    PascalCaseDirective,
    ForceChangePasswordComponent,
    ForceChangePasswordLeftComponent,
    SimpleInfoListModalComponent,
    EditInPlaceComponent,
    Z8FactorComponent,
    CollapsibleHistoryComponent,
    TierTagComponent,
    ImportOrderRequestComponent,
    FilesSubmitComponent,
    FilesSubmitComponent,
    HtmlViewerModalComponent,
    PromotionModalUploadComponent,
    DocumentViewerModalComponent,
    SafeUrlPipe
  ],
  imports: [
    NgxCaptureModule,
    SharedModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SortablejsModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    StoreModule.forRoot(appReducers, { metaReducers: [clearState] }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 15
        })
      : [],
    EffectsModule.forRoot([
      AuthEffects,
      ProductMasterEffects,
      RequestAssortmentEffects,
      ClassMarkupEffects,
      MasterDataEffects,
      LayoutEffects,
      SettingEffects,
      StackPricingEffects,
      FirstLotOrderEffects,
      MenuEffects,
      OrderEffects,
      OrderRequestEffects,
      OrderFixAssetRequestEffects,
      OrderInventoryRequestEffects,
      OrderSelectItemEffects,
      StoreEffects,
      StoreRequestEffects,
      UserEffects,
      PromotionEffects,
      TdAssortmentEffects,
      ForgotUsernameEffects,
      TdAssortmentEffects,
      DashboardEffects,
      ForgotPasswordEffects,
      ShelfRequestEffects,
      ShelfEffects,
      ShelfSelectFixAssetEffects,
      WarehouseEffects,
      WarehouseMasterEffects,
      FreshLiteEffects,
      BillToMasterEffects,
      SupplierPriceEffects,
      TaskRequestEffects,
      SupplierEffects,
      StockProductEffects,
      StoreDropdownEffects,
      ResponseAssignmentEffects,
      TaskAssignmentEffects,
      Customer360Effects,
      RoleEffects,
      ClaimEffects
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      level: loggerModuleOption(),
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    SortableModule.forRoot(),
    NgxSpinnerModule,
    CollapseModule,
    NgxMaskModule.forRoot(),
    GraphQLModule,
    NgImageSliderModule,
    ShelfComponentsModule,
    TDStoreModule,
    SafeHtmlModule,
    environment.enableGTM
      ? GoogleTagManagerModule.forRoot({
          id: environment.GTM_ID
        })
      : []
  ],
  providers: [
    AppInitService,
    AuthService,
    ErrorConnectorService,
    ProductService,
    AuthGuardService,
    ProductMasterService,
    CookieService,
    RequestAssortmentService,
    ProductAssortmentService,
    ClassMarkupService,
    MasterDataService,
    CommonUploadService,
    BarcodeService,
    ArticleService,
    SettingService,
    StackPricingService,
    ShelfRequestService,
    ShelfFixAssetRequestService,
    StockInformationService,
    MyReportService,
    MembershipService,
    StockLocationsService,
    StockLocationsByPermissionService,
    ShelfService,
    ShelfInventoryService,
    ShelfInventoryRequestService,
    ShelfFixAssetService,
    MerchantSelectValueService,
    MerchantRequestService,
    MerchantService,
    SupplierService,
    ShelfTypesService,
    OrderService,
    OrderRequestService,
    OrderStoreUseExportService,
    OrderFixAssetRequestService,
    OrderInventoryRequestService,
    OrderRequestExportService,
    ReceiveOrderService,
    DeliveryOrderService,
    DashboardService,
    ShipmentService,
    StoreReportService,
    StoreExportService,
    StoreGroupService,
    StoreRequestService,
    TDStoreWorkflowUtil,
    CookiesUtil,
    PermanentHoldService,
    PromotionService,
    PromotionRequestService,
    PurchaseRequestService,
    TdAssortmentService,
    OrderStoreUseService,
    PurchaseOrderService,
    GoodsReceiveService,
    RoleService,
    PermissionsService,
    MasterService,
    DecimalPipe,
    FileUrlService,
    KeysPipe,
    StoreDropdownService,
    WarehouseService,
    FreshLiteService,
    VoucherReportService,
    RewardRedeemTransactionReportService,
    RewardOfferTransactionReportService,
    SalesTransactionReportService,
    SalesTransactionConfidentialReportService,
    DisableButtonService,
    SalesSummaryReportService,
    NotifyService,
    PromotionCompensateReportService,
    MovingAverageReportService,
    TaskAssignmentRequestService,
    StoreLocationReportService,
    SupplierPriceConfigService,
    StockProductService,
    ExcelUtil,
    DatePipe,
    PermissionsUtil,
    StoresService,
    ManualSupplierPriceDataService,
    SupplierCouponReconcileReportService,
    DestroyService,
    TaskAssignmentResponseService,
    TaskAssignmentService,
    PurchaseProductPricesTemplateService,
    TaskResponsePermission,
    MemberRewardsService,
    TaskPermission,
    PreOrderRequestsService,
    PreOrderReportService,
    MemberExcludeItemService,
    PointEarnAndBurnReportService,
    ClaimService,
    ClaimReportService,
    StockTransferRequestService,
    HoldAssortmentReportService,
    environment.enableGTM ? GTMService : [],
    RefundTransactionConfidentialReport,
    OnlineCouponCompensateService,
    NewReportService,
    { provide: APP_INITIALIZER, useFactory: InitializeAppFactory, deps: [AppInitService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MetricInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GtmInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerLoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppGlobalErrorHandler },
    { provide: 'app-component-service', useValue: AppComponentService },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: 'googleTagManagerId', useValue: environment.enableGTM ? environment.GTM_ID : '' }
  ],
  exports: [OtpInputComponent],
  bootstrap: [AppComponent]
})
export class AppModule extends HmrModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
