import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ModalButtonResponseEnum } from '@shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '@shared/layouts';
import { ErrorResponse } from '@shared/models';

import { PasswordValidation } from '../../shared/custom-validators/password-validation';
import { ForceChangePasswordRequestAction } from '../../shared/store/actions/user-info.action';
import { selectUserInfoResult } from '../../shared/store/selectors/auth-user-info.selector';
import { selectForceChangePasswordResult } from '../../shared/store/selectors/user-selector';
import { AppStates } from '../../shared/store/state/app.states';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public changePasswordForm: UntypedFormGroup;
  public submitted;
  public forceChangePasswordResult$: Observable<any>;
  public userInfoResult$: Observable<any>;
  public userName: string;
  public isShowPassword: boolean;
  public isShowReEnterPassword: boolean;
  private localStore: Observable<any>;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<AppStates>,
    private readonly router: Router,
    private readonly translate: TranslateService,
    protected readonly modalService: BsModalService
  ) {
    super();
    this.changePasswordForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, PasswordValidation]],
        reEnterPassword: ['', [Validators.required]]
      },
      { validator: this.checkPasswords }
    );
  }

  get changePasswordFormGetter(): {
    [key: string]: AbstractControl;
  } {
    return this.changePasswordForm.controls;
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.forceChangePasswordResult$ = this.localStore.pipe(select(selectForceChangePasswordResult));
    this.submitted = false;

    this.forceChangePasswordResult$.subscribe(data => {
      if (data.result.response && data.result.response.statusCode === '200') {
        this.router.navigateByUrl('/');
      }
      if (!data.result.response && data.result.errorResponse) {
        if (['01008'].includes(data.result.errorResponse.code)) {
          this.alertAndBack(data.result.errorResponse);
        } else if (!data.result.response && data.result.errorResponse.code === '02002') {
          this.changePasswordFormGetter.newPassword.setErrors({ passwordSamePrevious: true });
        }
      }
    });
    this.userInfoResult$ = this.localStore.pipe(select(selectUserInfoResult));
    this.userInfoResult$.pipe(filter(value => Boolean(value))).subscribe(userInfo => {
      this.userName = userInfo.userName;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      this.store.dispatch(
        new ForceChangePasswordRequestAction({
          userName: this.userName,
          newPassword: this.changePasswordFormGetter.newPassword.value
        })
      );
    }
  }

  checkPasswords(group: UntypedFormGroup) {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.reEnterPassword.value;

    return pass === confirmPass ? null : group.controls.reEnterPassword.setErrors({ passwordNotEquivalent: true });
  }

  toggleInputPasswordType() {
    this.isShowPassword = !this.isShowPassword;
  }

  toggleInputReEnterPasswordType() {
    this.isShowReEnterPassword = !this.isShowReEnterPassword;
  }

  clearPasswordSamePreviousError() {
    this.changePasswordFormGetter.newPassword.setErrors(null);
  }

  alertAndBack(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant('ERROR_CODE.' + errorResponse.code)
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();

        this.router.navigateByUrl('/');
      }
    });
  }
}
