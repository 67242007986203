import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DynamicReportLogic } from '@shared/components/dynamic-report-modal/dynamic-report-logic/dynamic-report-logic';
import { ExportReportService } from '@shared/services/export-report.service';
import { OnlineCouponTransactionService } from '@shared/services/online-coupon-transaction.service';
import { PointEarnAndBurnReportService } from '@shared/services/point-earn-and-burn-report.service';
import { PointEarnTransactionReport } from '@shared/services/point-earn-transaction.service';
import { ProfitSharingService } from '@shared/services/profit-sharing.service';
import { ReceiveOrderService } from '@shared/services/receive-order.service';
import { RefundTransactionConfidentialReport } from '@shared/services/refund-transaction-confidential.service';
import { RefundService } from '@shared/services/refund.service';
import { RewardCatalogService } from '@shared/services/reward-catalog.service';
import { SpecialPointReportService } from '@shared/services/special-point-report.service';
import { SupplierCouponReconcileReportService } from '@shared/services/supplier-coupon-reconcile-report.service';
import { TaskAssignmentReportService } from '@shared/services/task-assignment-report.service';
import { VoucherReportService } from '@shared/services/voucher-report.service';
import { Z8HoldRequestService } from '@shared/services/z8-hold-request.service';

import { FlashSaleDashboardReportService } from '../../../pages/flash-sale-dashboard/services/flash-sale-dashboard-report.service';
import { ReturnToWarehouseChargeService } from '../../../pages/return-to-warehouse/services/return-to-warehouse-charge.service';
import { ReturnToWarehouseGRService } from '../../../pages/return-to-warehouse/services/return-to-warehouse-gr.service';
import { ReturnToWarehouseOrderService } from '../../../pages/return-to-warehouse/services/return-to-warehouse-order.service';
import { RewardService } from '../../../pages/reward/services/reward.service';
import { StoreAssortmentService } from '../../../pages/store-assortment/services/store-assortment.service';
import { StoreConsultantService } from '../../../pages/store-consultant/services/store-consultant.service';
import { ToteReportService } from '../../../pages/tote-information/services/tote-report.service';
import { AwaitingAllocationForGroupOrderService } from '../../services/awaiting-allocation-for-group-order.service';
import { GroupOrderReportService } from '../../services/group-order-report.service';
import { MasterService } from '../../services/master.service';
import { PreOrderTransactionsReportService } from '../../services/pre-order-transactions-report.service';
import { PromotionCompensateReportService } from '../../services/promotion-compensate-report.service';
import { SalesSummaryReportService } from '../../services/sales-summary-report.service';
import { SalesTransactionReportService } from '../../services/sales-transaction-report.service';
import { SpecialPointCompensateService } from '../../services/special-point-compensate.service';
import { StoreService } from '../../services/store.service';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { DynamicReportModalComponent } from './dynamic-report-modal.component';
import { DynamicReportsEffects } from './stores/dynamic-reports.effects';
import { dynamicReportReducer } from './stores/dynamic-reports.reducer';

@NgModule({
  declarations: [DynamicReportModalComponent],
  imports: [
    CommonModule,
    DynamicFormModule,
    StoreModule.forFeature('dynamicReport', dynamicReportReducer),
    EffectsModule.forFeature([DynamicReportsEffects])
  ],
  exports: [DynamicReportModalComponent],
  providers: [
    StoreService,
    MasterService,
    GroupOrderReportService,
    AwaitingAllocationForGroupOrderService,
    Z8HoldRequestService,
    ReceiveOrderService,
    PreOrderTransactionsReportService,
    PromotionCompensateReportService,
    SalesSummaryReportService,
    SalesTransactionReportService,
    SupplierCouponReconcileReportService,
    VoucherReportService,
    SpecialPointCompensateService,
    SpecialPointReportService,
    ReturnToWarehouseOrderService,
    ReturnToWarehouseGRService,
    TaskAssignmentReportService,
    FlashSaleDashboardReportService,
    OnlineCouponTransactionService,
    ExportReportService,
    ToteReportService,
    RefundService,
    RefundTransactionConfidentialReport,
    ProfitSharingService,
    ProfitSharingService,
    StoreConsultantService,
    StoreAssortmentService,
    RewardService,
    PointEarnTransactionReport,
    PointEarnAndBurnReportService,
    RewardCatalogService,
    ReturnToWarehouseChargeService,
    RewardCatalogService,
    DynamicReportLogic
  ]
})
export class DynamicReportModalModule {}
