import { createAction, props } from '@ngrx/store';

import { GoodsReceiveExportCriteria } from '@shared/models/goods-receive.model';
import { ProfitSharingExportCriteria } from '@shared/models/profit-sharing.model';
import { PurchaseOrderExportCriteria } from '@shared/models/purchase-order.model';
import { PurchaseRequestExportCriteria } from '@shared/models/purchase-request.model';
import { RewardCatalogExportCriteria } from '@shared/models/reward-catalog.model';
import { StockTransferGRExportCriteria } from '@shared/models/stock-transfer-gr.model';

import { ClaimRequestExportCriteria } from '../../../../pages/claim-request/model/claim-request.model';
import { ExportAdjustCriteria } from '../../../../pages/manage-stock/models/adjust-stock.model';
import {
  OnlineCouponExportCriteria,
  OnlineCouponSummaryCriteria
} from '../../../../pages/online-coupon/model/online-coupon.model';
import { RewardExportCriteria } from '../../../../pages/reward/models/reward.model';
import { ExportStoreAssortmentByProductCriteria } from '../../../../pages/store-assortment/models/store-assortment-request-store.model';
import { StoreConsultantExportCriteria } from '../../../../pages/store-consultant/models/store-consultant.model';
import { ExportFileTypeEnum } from '../../../enum/export-file-type.enum';
import {
  ExportAssortmentSearchCriteria,
  ExportStockByLocationCriteria,
  ExportStockByProductCriteria,
  ShipmentExportCriteria,
  StoreExportCriteria,
  StoreExportOrderScheduleCriteria
} from '../../../models';
import { DeliveryOrderExportPickingCriteria } from '../../../models/delivery-order.model';
import { OrderExportCriteria } from '../../../models/order.model';
import { HoldAssortmentReportCriteria } from '../../../models/permanent-hold.model';
import { ReceiveOrderExportCriteria } from '../../../models/receive-order.model';
import {
  AwaitingAllocationForGroupOrderReportCriteria,
  AwaitingFirstLotExportCriteria,
  CrossDockInvoiceReportCriteria,
  DcAssortmentExportCriteria,
  DestroyDocumentReportCriteria,
  DestroyTransactionReportExportCriteria,
  FlashSaleOrderReportCriteria,
  GroupOrderUnpickedItemReportCriteria,
  HoldOrderReportCriteria,
  InventoryAdjustmentRequestReportExportCriteria,
  InventoryCountTransactionReportExportCriteria,
  InventoryCountTransactionTempReportExportCriteria,
  InventoryVarianceCriteria,
  ManualChangeDeliveryStatusReportCriteria,
  MemberRewardTransactionCriteria,
  MoneyInReportCriteria,
  OnlineCouponCompensateCriteria,
  OnlineCouponTransactionCriteria,
  OrderProcessingHoldCriteria,
  PointBurnTransactionReportCriteria,
  PointEarnTransactionReportCriteria,
  PreOrderCompensateReportCriteria,
  PreOrderCreditRemainingReportCriteria,
  PreOrderTransactionReportCriteria,
  PromotionCompensateReportExportCriteria,
  PromotionReportCriteria,
  ReceiveOrderExceedReportCriteria,
  RedeemTransactionReportCriteria,
  RefundExportCriteria,
  ResponseReportCriteria,
  ReturnToWarehouseChargeReportCriteria,
  ReturnToWarehouseGRReportCriteria,
  ReturnToWarehouseOrderReportCriteria,
  RewardTransactionReportCriteria,
  SalesSummaryReportExportCriteria,
  SalesTransactionExportCriteria,
  SpecialPointCompensateReportCriteria,
  SpecialPointReportCriteria,
  StockCardExportCriteria,
  StoreLocationByDcReportCriteria,
  StoreToteReportCriteria,
  SupplierCouponReconcileReportExportCriteria,
  SurveyReportCriteria,
  TaskAssignmentReportCriteria,
  ToteAdjustmentReportCriteria,
  ToteFineReportCriteria,
  ToteInOutReportCriteria,
  ToteInTrackingReportCriteria,
  UserReportCriteria,
  VoucherCriteria
} from '../../../models/report.model';
import { StockTransferExportCriteria } from '../../../models/stock-transfer.model';

export const dynamicReportAction = {
  // export report actions
  moneyIn: createAction('[Dynamic Report] Money In Report', props<{ payload: MoneyInReportCriteria }>()),
  toteAdjustment: createAction(
    '[Dynamic Report] Tote Adjustment',
    props<{ payload: ToteAdjustmentReportCriteria }>()
  ),
  holdAssortment: createAction('[Dynamic Report] Permanent Hold', props<{ payload: HoldAssortmentReportCriteria }>()),
  groupOrderUnpickedItem: createAction(
    '[Dynamic Report] Group Order Unpicked Item ',
    props<{ payload: GroupOrderUnpickedItemReportCriteria }>()
  ),
  awaitingAllocationForGroupOrder: createAction(
    '[Dynamic Report] Awaiting Allocation For Group Order',
    props<{ payload: AwaitingAllocationForGroupOrderReportCriteria }>()
  ),
  memberRewardTransactions: createAction(
    '[Dynamic Report] Member Reward Transactions',
    props<{ payload: MemberRewardTransactionCriteria }>()
  ),
  memberRewardTransactionsWithPrice: createAction(
    '[Dynamic Report] Member Reward Transactions With Price',
    props<{ payload: MemberRewardTransactionCriteria }>()
  ),
  billPayment: createAction('[Dynamic Report] Bill Payment', props<{ payload: any }>()),
  orderProcessingHold: createAction(
    '[Dynamic Report] Order Processing Hold',
    props<{ payload: OrderProcessingHoldCriteria }>()
  ),
  receiveOrderExceed: createAction(
    '[Dynamic Report] Receive Order Exceed',
    props<{ payload: ReceiveOrderExceedReportCriteria }>()
  ),
  redeemTransaction: createAction(
    '[Dynamic Report] Redeem Transaction',
    props<{ payload: RedeemTransactionReportCriteria }>()
  ),
  rewardTransaction: createAction(
    '[Dynamic Report] Reward Transaction',
    props<{ payload: RewardTransactionReportCriteria }>()
  ),
  preOrderCompensate: createAction(
    '[Dynamic Report] Pre Order Compensate',
    props<{ payload: PreOrderCompensateReportCriteria }>()
  ),
  promotionCompensate: createAction(
    '[Dynamic Report] Promotion Compensate',
    props<{ payload: PromotionCompensateReportExportCriteria }>()
  ),
  salesSummary: createAction(
    '[Dynamic Report] Sales Summary',
    props<{ payload: SalesSummaryReportExportCriteria }>()
  ),
  salesTransaction: createAction(
    '[Dynamic Report] Sales Transaction',
    props<{ payload: SalesTransactionExportCriteria }>()
  ),
  supplierCouponReconcile: createAction(
    '[Dynamic Report] Supplier Coupon Reconcile',
    props<{ payload: SupplierCouponReconcileReportExportCriteria }>()
  ),
  voucher: createAction('[Dynamic Report] Voucher', props<{ payload: VoucherCriteria }>()),
  inventoryAdjustmentRequest: createAction(
    '[Dynamic Report] Inventory Adjustment Request',
    props<{ payload: InventoryAdjustmentRequestReportExportCriteria }>()
  ),
  destroyDocument: createAction(
    '[Dynamic Report] Destroy Document',
    props<{ payload: DestroyDocumentReportCriteria }>()
  ),
  inventoryVariance: createAction(
    '[Dynamic Report] Inventory Variance',
    props<{ payload: InventoryVarianceCriteria }>()
  ),
  destroyTransaction: createAction(
    '[Dynamic Report] Destroy Transaction',
    props<{ payload: DestroyTransactionReportExportCriteria }>()
  ),
  inventoryCountTransactions: createAction(
    '[Dynamic Report] Inventory Count Transaction',
    props<{ payload: InventoryCountTransactionReportExportCriteria }>()
  ),
  inventoryCountTransactionsWithPrice: createAction(
    '[Dynamic Report] Inventory Count Transaction With Price',
    props<{ payload: InventoryCountTransactionTempReportExportCriteria }>()
  ),
  preOrderTransaction: createAction(
    '[Dynamic Report] Pre Order Transaction',
    props<{ payload: PreOrderTransactionReportCriteria }>()
  ),
  preOrderTransactionWithPrice: createAction(
    '[Dynamic Report] Pre Order Transaction With Price',
    props<{ payload: PreOrderTransactionReportCriteria }>()
  ),
  promotion: createAction('[Dynamic Report] Promotion', props<{ payload: PromotionReportCriteria }>()),
  specialPointCompensate: createAction(
    '[Dynamic Report] Special Point Compensate',
    props<{ payload: SpecialPointCompensateReportCriteria }>()
  ),
  specialPoint: createAction('[Dynamic Report] Special Point', props<{ payload: SpecialPointReportCriteria }>()),
  shipment: createAction('[Dynamic Report] Shipment', props<{ payload: ShipmentExportCriteria }>()),
  response: createAction('[Dynamic Report] Response', props<{ payload: ResponseReportCriteria }>()),
  survey: createAction('[Dynamic Report] Survey', props<{ payload: SurveyReportCriteria }>()),
  returnToWarehouseOrder: createAction(
    '[Dynamic Report] Return To Warehouse Order',
    props<{ payload: ReturnToWarehouseOrderReportCriteria }>()
  ),
  returnToWarehouseGR: createAction(
    '[Dynamic Report] Return To Warehouse GR',
    props<{ payload: ReturnToWarehouseGRReportCriteria }>()
  ),
  returnToWarehouseCharge: createAction(
    '[Dynamic Report] Return To Warehouse Charge',
    props<{ payload: ReturnToWarehouseChargeReportCriteria }>()
  ),
  flashSaleOrder: createAction(
    '[Dynamic Report] Flash Sale Order',
    props<{ payload: FlashSaleOrderReportCriteria }>()
  ),
  onlineCouponTransaction: createAction(
    '[Dynamic Report] Online Coupon Transaction',
    props<{ payload: OnlineCouponTransactionCriteria }>()
  ),
  onlineCouponCompensate: createAction(
    '[Dynamic Report] Online Coupon Compensate',
    props<{ payload: OnlineCouponCompensateCriteria }>()
  ),
  taskAssignment: createAction(
    '[Dynamic Report] Task Assignment',
    props<{ payload: TaskAssignmentReportCriteria }>()
  ),
  order: createAction('[Dynamic Report] Order', props<{ payload: OrderExportCriteria }>()),
  salesTransactionConfidential: createAction(
    '[Dynamic Report] Sales Transaction Confidential',
    props<{ payload: SalesTransactionExportCriteria }>()
  ),
  refundSummary: createAction('[Dynamic Report] Refund Summary', props<{ payload: RefundExportCriteria }>()),
  refundTransaction: createAction('[Dynamic Report] Refund Transaction', props<{ payload: RefundExportCriteria }>()),
  deliveryOrder: createAction(
    '[Dynamic Report] Delivery Order',
    props<{ payload: DeliveryOrderExportPickingCriteria }>()
  ),
  receiveOrder: createAction('[Dynamic Report] Receive Order', props<{ payload: ReceiveOrderExportCriteria }>()),
  claim: createAction('[Dynamic Report] Claim', props<{ payload: ClaimRequestExportCriteria }>()),
  awaitingFirstLot: createAction(
    '[Dynamic Report] Awaiting First Lot',
    props<{ payload: AwaitingFirstLotExportCriteria }>()
  ),
  holdOrder: createAction('[Dynamic Report] Hold Order', props<{ payload: HoldOrderReportCriteria }>()),
  stockByProduct: createAction(
    '[Dynamic Report] Stock By Product',
    props<{ payload: ExportStockByProductCriteria }>()
  ),
  stockAdjustment: createAction('[Dynamic Report] Stock Adjustment', props<{ payload: ExportAdjustCriteria }>()),
  stockCard: createAction('[Dynamic Report] Stock Card', props<{ payload: StockCardExportCriteria }>()),
  transferOrder: createAction('[Dynamic Report] Transfer Order', props<{ payload: StockTransferExportCriteria }>()),
  transferGoodsReceive: createAction(
    '[Dynamic Report] Transfer Goods Receive',
    props<{ payload: StockTransferGRExportCriteria }>()
  ),
  user: createAction('[Dynamic Report] User', props<{ payload: UserReportCriteria }>()),
  profitSharing: createAction('[Dynamic Report] Profit Sharing', props<{ payload: ProfitSharingExportCriteria }>()),
  storeConsultant: createAction(
    '[Dynamic Report] Store Consultant',
    props<{ payload: StoreConsultantExportCriteria }>()
  ),
  store: createAction('[Dynamic Report] Store', props<{ payload: StoreExportCriteria }>()),
  storeConfidential: createAction('[Dynamic Report] Store Confidential', props<{ payload: StoreExportCriteria }>()),
  storeOrderSchedule: createAction(
    '[Dynamic Report] Store Order Schedule',
    props<{ payload: StoreExportOrderScheduleCriteria }>()
  ),
  storeLocationByDc: createAction(
    '[Dynamic Report] Store Location By DC',
    props<{ payload: StoreLocationByDcReportCriteria }>()
  ),
  tdAssortment: createAction('[Dynamic Report] TD Assortment', props<{ payload: ExportAssortmentSearchCriteria }>()),
  tdAssortmentConfidential: createAction(
    '[Dynamic Report] TD Assortment Confidential',
    props<{ payload: ExportAssortmentSearchCriteria }>()
  ),
  assortmentLocation: createAction(
    '[Dynamic Report] Assortment Location',
    props<{ payload: ExportStoreAssortmentByProductCriteria }>()
  ),
  dcAssortment: createAction('[Dynamic Report] DC Assortment', props<{ payload: DcAssortmentExportCriteria }>()),
  movingAverage: createAction('[Dynamic Report] Moving Average', props<{ payload: any }>()),
  preOrderCatalog: createAction('[Dynamic Report] Pre Order Catalog', props<{ payload: any }>()),
  preOrderCatalogConfidential: createAction(
    '[Dynamic Report] Pre Order Catalog Confidential',
    props<{ payload: any }>()
  ),
  shelfFixAsset: createAction('[Dynamic Report] Shelf Fix Asset', props<{ payload: any }>()),
  shelfInventory: createAction('[Dynamic Report] Shelf Inventory', props<{ payload: any }>()),
  storeAssortment: createAction('[Dynamic Report] Store Assortment', props<{ payload: any }>()),
  reward: createAction('[Dynamic Report] Reward', props<{ payload: RewardExportCriteria }>()),
  pointEarnTransaction: createAction(
    '[Dynamic Report] Point Earn Transaction',
    props<{ payload: PointEarnTransactionReportCriteria }>()
  ),
  pointBurnTransaction: createAction(
    '[Dynamic Report] Point Burn Transaction',
    props<{ payload: PointBurnTransactionReportCriteria }>()
  ),
  onlineCoupon: createAction('[Dynamic Report] Online Coupon', props<{ payload: OnlineCouponExportCriteria }>()),
  onlineCouponSummary: createAction(
    '[Dynamic Report] Online Coupon Summary',
    props<{ payload: OnlineCouponSummaryCriteria }>()
  ),
  memberRewardCatalog: createAction(
    '[Dynamic Report] Member Reward Catalog',
    props<{ payload: RewardCatalogExportCriteria }>()
  ),
  stockByLocation: createAction(
    '[Dynamic Report] Stock By Location',
    props<{ payload: ExportStockByLocationCriteria }>()
  ),
  purchaseRequest: createAction(
    '[Dynamic Report] Purchase Request',
    props<{ payload: PurchaseRequestExportCriteria }>()
  ),
  purchaseOrder: createAction('[Dynamic Report] Purchase Order', props<{ payload: PurchaseOrderExportCriteria }>()),
  goodsReceive: createAction('[Dynamic Report] Goods Receive', props<{ payload: GoodsReceiveExportCriteria }>()),
  preOrderCreditRemaining: createAction(
    '[Dynamic Report] Pre Order Credit Remaining',
    props<{ payload: PreOrderCreditRemainingReportCriteria }>()
  ),
  crossDockInvoice: createAction(
    '[Dynamic Report] Cross Dock Invoice',
    props<{ payload: CrossDockInvoiceReportCriteria }>()
  ),
  storeTote: createAction('[Dynamic Report] Store Tote', props<{ payload: StoreToteReportCriteria }>()),
  toteFine: createAction('[Dynamic Report] Tote Fine', props<{ payload: ToteFineReportCriteria }>()),
  toteInOut: createAction('[Dynamic Report] Tote In Out', props<{ payload: ToteInOutReportCriteria }>()),
  toteInTracking: createAction(
    '[Dynamic Report] Tote In Tracking',
    props<{ payload: ToteInTrackingReportCriteria }>()
  ),
  manualChangeDeliveryStatus: createAction(
    '[Dynamic Report] Manual Change Delivery Status',
    props<{ payload: ManualChangeDeliveryStatusReportCriteria }>()
  ),
  clearAllStates: createAction('[Dynamic Report] Clear States'),
  // handle response(file, message), error
  exportReportResponse: createAction(
    '[Dynamic Report] Export Report Response',
    props<{ isSuccess: boolean; response: any }>()
  ),
  exportReportFileResponse: createAction(
    '[Dynamic Report] Export Report File Response',
    props<{ isSuccess: boolean; response: any; fileName: string; extension?: ExportFileTypeEnum }>()
  ),
  exportReportError: createAction(
    '[Dynamic Report] Export Report Error',
    props<{ isSuccess: boolean; error: any }>()
  ),
  exportReportFileError: createAction(
    '[Dynamic Report] Export File Report Error',
    props<{ isSuccess: boolean; error: any }>()
  )
};
